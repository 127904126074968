import React from "react";
import { Box, Typography, Button } from "@mui/material";
import ThreeDIcon from "../../../components/ui/ThreeDIcon";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../theme/Colors";

const IconWithText = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box display="flex" justifyContent="center" sx={{ marginBottom: "32px" }}>
        <ThreeDIcon name="error" />
      </Box>

      <Box display="flex" flexDirection="column" textAlign="center" sx={{ marginBottom: "32px" }}>
        <Typography variant="text_xl" fontSize="25px" fontWeight="700">
          Oops...
        </Typography>
        <Typography variant="text_normal" color={colors.text_subtitle}>
          Veuillez vérifier vos informations et réessayer
        </Typography>
      </Box>

      <Box sx={{ marginBottom: "32px", gap: "20px", display: "flex", flexDirection: "column", textAlign: "justify" }}>
        <Box>
          <Typography variant="text_normal">Nous sommes désolés, mais nous n'avons pas pu créer votre annonce en raison d'une erreur. Veuillez vérifier les informations que vous avez fournies et réessayer.</Typography>
        </Box>
        <Box>
          <Typography variant="text_normal">Si le problème persiste, veuillez contacter notre équipe d'assistance pour obtenir de l'aide. Nous ferons de notre mieux pour résoudre ce problème rapidement. support@flextalent.com</Typography>
        </Box>
        <Box>
          <Typography variant="text_normal" color={colors.main} fontWeight="700">
            support@flextalent.com
          </Typography>
        </Box>
      </Box>

      <Box>
        <Button fullWidth variant="mj_secondary_md" onClick={() => navigate("/entreprise/offres/option-create-offer")}>
          Réessayer
        </Button>
      </Box>
    </>
  );
};

function FailureCreateOffer() {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
      <Box flex={1} display="flex" flexDirection="column" className="left" justifyContent="center" alignItems="center">
        <Box sx={{ alignItems: "center", background: "white", width: "100%", maxWidth: "416px", padding: "68px 16px", minHeight: "586px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <Box width="100%">
            <IconWithText />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default FailureCreateOffer;
