import React, { useState, useEffect } from "react";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { Box, TextField, Button, Typography, Checkbox, IconButton, FormLabel, Radio, FormControlLabel, RadioGroup, MenuItem, OutlinedInput, Select, InputLabel, FormControl, Alert, Modal, Autocomplete } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ButtonDataGrid from "../../components/ui/ButtonDataGrid";
import { chipSelector } from "../../utils/chipSelector";
import leftArrowIcon from "../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../imgs/imgV2/arrowRightBlavk.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LinearProgress from "@mui/material/LinearProgress";
import { rows, columns } from "../../data/OffresData";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import { Formik, Form, Field, useFormik } from "formik";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { useAuthContext } from "../../hooks/useAuthContext";
import axios from "axios";
import * as yup from "yup";
import { colors } from "../../theme/Colors";
import { ArrowBackIosOutlined } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useLocation, useNavigate } from "react-router";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ButtonSquare from "../../components/ui/ButtonSquare";
import MyChip from "../../components/ui/MyChip";
import { modalStyle } from "../../theme/ModalStyle";
import BoxContainer from "../../components/ui/BoxContainer";
import { ToastContainer, toast } from "react-toastify";

function GestionCandidatures() {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const [action, setAction] = useState("");
  const [openParams, setOpenParams] = React.useState(false);
  const { user } = useAuthContext();
  const location = useLocation();
  const idInt = location?.state?.idinterim ? location?.state?.idinterim : "";
  const [rows, setRows] = useState([]);
  const [id, setId] = useState(null);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(false);

  const [rowsPerPage, setRowsPer] = useState(10);
  const [finalValues, setFieldValue] = useState(0);

  const validationSchema = yup.object().shape({
    tauxHoraireMax: yup.number().typeError("entrer un nombre").min(0, "entrer un nombre valide"),
    tauxHoraireMin: yup.number().typeError("entrer un nombre").min(0, "entrer un nombre valide"),
  });
  const [status, setStatus] = useState([]);
  const [checkedState, setCheckedState] = useState({
    checked1: false,
    checked2: false,
    checked3: false,
    checked4: false,
    checked5: false,
    checked6: false,
    checked7: false,
    checked8: false,
  });
  const [checkedPostedBy, setCheckedPostedBy] = useState({
    checked1: false,
    checked2: false,
  });
  const statusCand = {
    PENDING: { label: "En attente", variant: "skill" },
    ACCEPTED: { label: "Acceptée", variant: "ended_offer" },
    APPLICATION_REJECTED: { label: "Refusée", variant: "rejected_offer" },
    OFFER_REJECTED: { label: "Offre rejetée", variant: "rejected_offer" },
    DOCUMENTS_PENDING_VALIDATION: { label: "En attente de document", variant: "skill" },
    CONTRACT_IN_CREATION: { label: "Création du contrat", variant: "skill" },
    WAITING_INTERIM_SIGNATURE: { label: "Attente signature intérimaire", variant: "skill" },
    WAITING_ENTERPRISE_SIGNATURE: { label: "Attente signature entreprise", variant: "skill" },
    APPLIED: { label: "Postulée", variant: "skill" },
    APPLICATION_CANCELED: { label: "Candidature annulée", variant: "rejected_offer" },
    MISSION: { label: "En mission", variant: "active_offer" },
  };
  console.log("interim id", location?.state?.idInt);

  const [postedBy, setPostedBy] = useState("");
  const [initialValues, setInitialValues] = useState({
    id: "",
    entreprise: "",
    offerId: "",
    enterpriseId: "",
    interimaire: "",
    interimaireId: idInt,
    poste: "",
    status: "",
  });
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
  });
  const access_token = user?.access_token;

  //GET LISTE DES OFFRES + FILTRE
  useEffect(() => {
    setLoading(true);
    Api.get(Urls.GET_CANDIATURE + `?perPage=${25}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(formik.values.id !== "" && { applicationId: formik.values.id }),
        ...(formik.values.entreprise !== "" && { enterpriseName: formik.values.entreprise }),
        ...(formik.values.enterpriseId !== "" && { enterpriseId: formik.values.enterpriseId }),
        ...(formik.values.interimaireId !== "" && { interimId: formik.values.interimaireId }),
        ...(formik.values.offerId !== "" && { offerId: formik.values.offerId }),
        ...(formik.values.status !== "" && { matchingLevel: formik.values.status }),
        ...(formik.values.interimaire !== "" && { interimName: formik.values.interimaire }),
        ...(formik.values.poste !== "" && { jobTitle: formik.values.poste }),
        ...(postedBy !== "" && { postedBy: postedBy }),
        ...(status !== "" && { status: status }),
      },
    })
      .then((res) => {
        console.log(res);
        setRows(res.data.applications);
        setData(res.data);
        setLoading(false);
        setTotal(res?.data?.meta?.total);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [formik.values, change, postedBy, status]);
  //handle Pagination
  const handlePagination = (link) => {
    setLoading(true);
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("RESULTAT MATCHED offres DATA", res?.data);
        console.log("RESPONES GHERBI", res);
        setRows(res.data.applications);
        setData(res.data);
        setLoading(false);
        setTotal(res?.data?.meta?.total);
        console.log(res?.data?.meta?.links, "link pag");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setRows([]);
      });
  };
  const matchings = {
    perfect: "Parfait",
    suitable: "Satisfaisant",
    weak: "Mauvais",
  };

  const columns = [
    { field: "id", headerName: "ID demande", width: 90, headerClassName: "grid-header" },
    {
      field: "user_id",
      headerName: "ID intérimaire",
      width: 100,
      renderCell: (params) => {
        if (params.row.user_id === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.user_id;
        }
      },
    },
    {
      field: "enterprise_id",
      headerName: "ID Entreprise",
      width: 150,
      renderCell: (params) => {
        if (params.row.enterprise_id === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.enterprise_id;
        }
      },
    },
    {
      field: "offer_id",
      headerName: "ID offre",
      width: 150,
      renderCell: (params) => {
        if (params.row.offer_id === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.offer_id;
        }
      },
    },
    {
      field: "user_first_name",
      headerName: "Intérimaire",
      width: 200,
      renderCell: (params) => {
        return params.row.user_first_name + " " + params.row.user_last_name;
      },
    },
    {
      field: "enterprise_name",
      headerName: "Entreprise",
      width: 100,
      align: "center",
      renderCell: (params) => {
        if (params.row.enterprise_name === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.enterprise_name;
        }
      },
    },
    /* {
      field: "is_visible",
      headerName: "Visibilité",
      width: 150,
      align: "center",
      renderCell: (params) => {
        if (params.row.is_visible === null) {
          return <Typography>Non defini</Typography>;
        } else {
          return chipSelector(params.row.is_visible);
        }
      },
    }, */
    {
      field: "job_title",
      headerName: "Offre",
      description: "This column has a value getter and is not sortable.",
      width: 200,
      align: "center",
      renderCell: (params) => {
        return params.row.job_title;
      },
    },
    {
      field: "matching_level",
      headerName: "Matching",
      description: "This column has a value getter and is not sortable.",
      width: 160,
      align: "center",
      renderCell: (params) => {
        return matchings[`${params.row.matching_level}`];
      },
    },
    {
      field: "calculated_status",
      headerName: "Etat",
      description: "This column has a value getter and is not sortable.",
      width: 200,
      align: "center",
      renderCell: (params) => {
        return <MyChip variant={statusCand[`${params.row.calculated_status}`]?.variant} label={statusCand[`${params.row.calculated_status}`]?.label} />;
      },
    },
    {
      field: "posted_by",
      headerName: "Postulée par",
      description: "This column has a value getter and is not sortable.",
      width: 150,
      align: "center",
      renderCell: (params) => {
        return <MyChip variant="active_offer" label={params.row.posted_by === "INTERIM" ? "Intérimaire" : "Admin"} />;
      },
    },
    {
      field: "action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",
      align: "center",

      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" gap="0.2rem" alignItems="center">
            <ArrowCircleRightIcon
              action={"details"}
              sx={{ cursor: "pointer", color: `${colors.mjGreenOutline}`, fontSize: 40 }}
              onClick={() =>
                navigate("/admin/gestion-des-candidatures/details/" + params.row.id, {
                  state: {
                    interimId: params.row.user_id,
                    enterpriseId: params.row.enterprise_id,
                    offerId: params.row.offer_id,
                  },
                })
              }
            />{" "}
          </Box>
        );
      },
      width: 160,
      valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
  ];
  //animate open params
  const animateOpen = {
    transform: "rotate(90deg)",
    transition: "0.25s",
  };
  const animateClose = {
    transform: "rotate(0deg)",
    transition: "0.25s",
  };

  const customFooter = () => {
    return <Box></Box>;
  };
  //publier offre
  const publishOffers = (status) => {
    Api.post(
      Urls.OPEN_CLOSE_OFFER,
      {
        offer_ids: selectedIDs,
        is_application_open: status,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res.data);
        setOpen(false);
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err.messages);
        toast.error(err?.response?.data.message);
      });
  };

  //delete offer
  const VerifyOffer = (status) => {
    Api.post(
      Urls.VERIFY_OFFER,
      {
        offer_ids: selectedIDs,
        is_verified: status,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res.data);
        setOpen(false);
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err.messages);
        toast.error(err?.response?.data.message);
      });
  };
  //accept offer
  const activateOffer = (status) => {
    Api.post(
      Urls.ACTIATE_DEACTIVATE_OFFER,
      {
        offer_ids: selectedIDs,
        is_active: status,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res.data);
        setOpen(false);
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err.messages);
        toast.error(err?.response?.data.message);
      });
  };

  return (
    <Box>
      {/* Header Section */}
      <ToastContainer />
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography noWrap variant="h4" component="h1">
          Gestion des candidatures
        </Typography>
      </Box>

      {/* Form Section */}
      <form onSubmit={formik.handleSubmit}>
        <Box className="filter" display="flex" flexDirection="column">
          <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column", lg: "column" } }}>
            <Box>
              <Typography noWrap mr marginTop="0.45rem" fontWeight="600">
                Filtre :
              </Typography>
            </Box>

            {/*Search fields  */}

            <Box display="flex" flex={1} alignItems="center" marginRight="1rem" gap="1rem" flexWrap="wrap" mb>
              <Box gap="0.3rem" display="flex" alignItems="center">
                <label style={{ marginRight: "0.5rem" }} htmlFor="interimaire">
                  ID candidature
                </label>
                <TextField type="number" name="id" onChange={formik.handleChange} placeholder="ID candidature" />
              </Box>
              <Box gap="0.3rem" display="flex" alignItems="center">
                <label style={{ marginRight: "0.5rem" }} htmlFor="interimaire">
                  ID Entreprise
                </label>
                <TextField name="entrepriseId" onChange={formik.handleChange} placeholder="ID entreprise" />
              </Box>
              <Box gap="0.3rem" display="flex" alignItems="center">
                <label style={{ marginRight: "0.5rem" }} htmlFor="interimaire">
                  ID intérimaire
                </label>
                <TextField type="number" name="interimaireId" value={formik.values.interimaireId} onChange={formik.handleChange} placeholder="ID intérimaire" />
              </Box>
              <Box gap="0.3rem" display="flex" alignItems="center">
                <label htmlFor="intitulePoste">ID offre : </label>
                <TextField name="offerId" onChange={formik.handleChange} placeholder="ID offre " />
              </Box>
            </Box>
            <Box display="flex" flex={1} alignItems="center" marginRight="1rem" gap="1rem" flexWrap="wrap" mb>
              <Box gap="0.3rem" display="flex" alignItems="center">
                <label style={{ marginRight: "0.5rem" }} htmlFor="interimaire">
                  Entreprise
                </label>
                <TextField type="number" name="entreprise" onChange={formik.handleChange} placeholder="Entreprise" />
              </Box>
              <Box gap="0.3rem" display="flex" alignItems="center">
                <label style={{ marginRight: "0.5rem" }} htmlFor="interimaire">
                  Intérimaire
                </label>
                <TextField name="interimaire" onChange={formik.handleChange} placeholder="Intérimaire" />
              </Box>
              <Box gap="0.3rem" display="flex" alignItems="center">
                <label style={{ marginRight: "0.5rem" }} htmlFor="interimaire">
                  Matching
                </label>
                <Box>
                  <Autocomplete
                    sx={{ minWidth: "200px" }}
                    fullWidth
                    options={[
                      { id: "perfect", name: "Parfait" },
                      { id: "suitable", name: "Satisfaisant" },
                      { id: "weak", name: "Mauvais" },
                    ]}
                    //options={candidaturesStates}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(e) => e.name}
                    onChange={(event, value) => {
                      if (value !== null) {
                        console.log("selecting candidature state value", value);
                        console.log("selecting candidature state value", value.id);
                        formik.setFieldValue("status", value.id);
                        console.log(value);
                        console.log("selectedCandidatureState");
                      } else {
                        formik.setFieldValue("status", "");
                        console.log(null);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Tous" />}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          {/* Checkboxes */}
          <Box display="flex" flexDirection="column" flexWrap="wrap" gap="18px">
            <Typography fontSize="14px" fontWeight="500"></Typography>
            <Box display="flex" alignItems="center" gap="10px" flexWrap="wrap">
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setCheckedState({ ...checkedState, checked1: e.target.checked });
                        e.target.checked === true ? setStatus((status) => status.concat(["PENDING"])) : setStatus(status.filter((status) => status !== "PENDING"));
                      }}
                      checked={checkedState.checked1}
                    />
                  }
                  label="En attente"
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setCheckedState({ ...checkedState, checked2: e.target.checked });
                        e.target.checked === true ? setStatus((status) => status.concat(["ACCEPTED"])) : setStatus(status.filter((status) => status !== "ACCEPTED"));
                      }}
                      checked={checkedState.checked2}
                    />
                  }
                  label="Offre envoyée"
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setCheckedState({ ...checkedState, checked3: e.target.checked });
                        e.target.checked === true ? setStatus((status) => status.concat(["APPLICATION_REJECTED"])) : setStatus(status.filter((status) => status !== "APPLICATION_REJECTED"));
                      }}
                      checked={checkedState.checked3}
                    />
                  }
                  label="Refusée"
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setCheckedState({ ...checkedState, checked4: e.target.checked });
                        e.target.checked === true ? setStatus((status) => status.concat(["OFFER_REJECTED"])) : setStatus(status.filter((status) => status !== "OFFER_REJECTED"));
                      }}
                      checked={checkedState.checked4}
                    />
                  }
                  label="Proposition refusée"
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setCheckedState({ ...checkedState, checked5: e.target.checked });
                        e.target.checked === true ? setStatus((status) => status.concat(["DOCUMENTS_PENDING_VALIDATION"])) : setStatus(status.filter((status) => status !== "DOCUMENTS_PENDING_VALIDATION"));
                      }}
                      checked={checkedState.checked5}
                    />
                  }
                  label="En attente de documents"
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setCheckedState({ ...checkedState, checked6: e.target.checked });
                        e.target.checked === true ? setStatus((status) => status.concat(["WAITING_INTERIM_SIGNATURE"])) : setStatus(status.filter((status) => status !== "WAITING_INTERIM_SIGNATURE"));
                      }}
                      checked={checkedState.checked6}
                    />
                  }
                  label="En attente de signature (Intérimaire)"
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setCheckedState({ ...checkedState, checked7: e.target.checked });
                        e.target.checked === true ? setStatus((status) => status.concat(["WAITING_ENTERPRISE_SIGNATURE"])) : setStatus(status.filter((status) => status !== "WAITING_ENTERPRISE_SIGNATURE"));
                      }}
                      checked={checkedState.checked7}
                    />
                  }
                  label="En attente de signature (Entreprise)"
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setCheckedState({ ...checkedState, checked8: e.target.checked });
                        e.target.checked === true ? setStatus((status) => status.concat(["APPLIED"])) : setStatus(status.filter((status) => status !== "APPLIED"));
                      }}
                      checked={checkedState.checked8}
                    />
                  }
                  label="Postulé"
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setCheckedState({ ...checkedState, checked8: e.target.checked });
                        e.target.checked === true ? setStatus((status) => status.concat(["MISSION"])) : setStatus(status.filter((status) => status !== "MISSION"));
                      }}
                      checked={checkedState.checked8}
                    />
                  }
                  label="Mission"
                />
              </Box>
            </Box>
            <Box display="flex" flexWrap="wrap" gap="10px" alignItems="center">
              <Typography>Paramètres avancées :</Typography>
              <Box gap="0.3rem" display="flex" alignItems="center">
                <label style={{ marginRight: "0.5rem" }} htmlFor="interimaire">
                  Intitulé du poste
                </label>
                <TextField name="poste" onChange={formik.handleChange} placeholder="Intitulé du poste" />

                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setCheckedPostedBy({ checked2: e.target.checked ? false : checkedPostedBy.checked2, checked1: e.target.checked });
                          e.target.checked === true ? setPostedBy("admin") : setPostedBy("");
                        }}
                        checked={checkedPostedBy.checked1}
                      />
                    }
                    label="Postulé par l'admin"
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setCheckedPostedBy({ checked1: e.target.checked ? false : checkedPostedBy.checked1, checked2: e.target.checked });
                          e.target.checked === true ? setPostedBy("interim") : setPostedBy("");
                        }}
                        checked={checkedPostedBy.checked2}
                      />
                    }
                    label="Postulé par l'intérimaire"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* End form section */}

        {/* Actions Section */}
        <Box className="actions">
          <Box display="flex" alignItems="left" gap="10px" mb flexDirection="column">
            <Typography noWrap mr marginTop="0.4rem" fontWeight="600">
              Actions :
            </Typography>
            {/* actions etat */}
            <Box display="flex" flexDirection="column" gap="0.5rem">
              <Box display="flex" flexDirection="row" gap="0.5rem" alignItems="center">
                <Box display="flex" gap="1rem">
                  <Button onClick={() => console.log("test")} variant="mj_green_md">
                    Accepter les candidatures
                  </Button>
                </Box>
                <Box display="flex" gap="1rem">
                  <Button onClick={() => console.log("test")} variant="mj_red_md">
                    Refuser les candidatures
                  </Button>
                </Box>
              </Box>

              {/* actions visibilité */}
            </Box>
          </Box>
        </Box>
      </form>
      {/* Data grid section */}
      {/* Data grid container (styles imported from DataGridHeaderContainer) */}
      <Box sx={DataGridHeaderStyle}>
        <DataGrid
          // sx={{ maxWidth: "1550px" }}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          onSelectionModelChange={(ids) => {
            const selection = new Set(ids);
            setSelectedIDs(ids);
            const selectedRows = rows.filter((row) => selection.has(row.id));
            setSelectedRows(selectedRows);
          }}
          rows={rows}
          columns={columns}
          pageSize={rowsPerPage}
          rowCount={total}
          rowsPerPageOptions={[10, 30, 60]}
          onPageSizeChange={(size) => {
            setRowsPer(size);
          }}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
              labelRowsPerPage: `Nombre de lignes par page :`,
            },
            footerRowSelected: (count) => (count !== 1 ? `${count.toLocaleString()} lignes selectionnées` : `${count.toLocaleString()} ligne selectionnée`),
          }}
          checkboxSelection
          disableSelectionOnClick
          page={page - 1}
          paginationMode="server"
          onPageChange={(page) => {
            setPage(page + 1);
          }}
          loading={loading}
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
            Footer: customFooter,
          }}
        />
      </Box>
      {rows?.length > 0 && (
        <Box marginBottom="24px" marginTop="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
          <Box flex={1}>
            {data?.links?.prev && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={leftArrowIcon} alt="arrow-left" />
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
            {data?.meta?.links?.map((link, index) => (
              <>
                {index !== 0 && index !== data?.meta?.last_page + 1 && (
                  <ButtonSquare
                    onClick={() => {
                      handlePagination(link.url);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action={link?.active ? "number-active" : "number"}
                    number={link.label}
                  />
                )}
              </>
            ))}
          </Box>
          <Box flex={1}>
            {data?.links?.next && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={arrowRightIcon} alt="arrow-right" />
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default GestionCandidatures;
