import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { shadows } from "@mui/system";
import { useState, useEffect, useRef } from "react";
import { colors } from "../../../theme/Colors";
import { Box, Button, Typography, Stack, TextField, Modal, InputLabel, Icon, InputAdornment, IconButton, FormControl, Autocomplete, Switch, FormControlLabel, Divider, Checkbox } from "@mui/material";
import MySelect from "../../../components/ui/MySelect";
import styled from "@emotion/styled";
import lampIcon from "../../../imgs/imgV2/Lamp.svg";
import DocumentIcon from "../../../imgs/imgV2/document.svg";
import frLocale from "date-fns/locale/fr";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { modalStyle, modalStyle2 } from "../../../theme/ModalStyle";

import Card from "@mui/material/Card";
import MyDPFullDate from "../../../components/ui/MyDPFullDate";
import { useLocation, useNavigate } from "react-router-dom";
import ContentBox from "../../../components/ui/ContentBox";
import kmIcon from "../../../imgs/imgV2/km.svg";
import AnsIcon from "../../../imgs/imgV2/ansIcon.svg";
import euroIcon from "../../../imgs/imgV2/euroIcon.svg";

import leftArrowIcon from "../../../imgs/imgV2/leftArrowBlack.svg";
import OffreComponent from "../../../components/ui/OffreComponent";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useAuthContext } from "../../../hooks/useAuthContext";
import MyTextField from "../../../components/ui/MyTextField";
import arrowRightIcon from "../../../imgs/imgV2/arrowRightBlavk.svg";
import { Field, Form, Formik } from "formik";
import BoxContainer from "../../../components/ui/BoxContainer";
import MyChip from "../../../components/ui/MyChip";
import RechercheIcon from "../../../imgs/imgV2/RechercheIcon.svg";
import { DeleteOutline } from "@mui/icons-material";
import ButtonSquare from "../../../components/ui/ButtonSquare";

import * as yup from "yup";
import DetailOffre from "../DetailOffre";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

function TrouverDuTravail() {
  //invoke current user

  const [selectButton, setSelectButton] = useState("choice1");
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const navigate = useNavigate();
  const [offres, setOffres] = useState([]);
  const [data, setData] = useState([]);
  const location = useLocation();
  const iduser = location.pathname.split("/")[4];
  const nom = location?.state?.nom;
  const prenom = location?.state?.prenom;
  const perPage = 8;
  const [change, setChange] = useState(false);
  const [checkedPostuler, setCheckedPostuler] = useState(false);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [metierListe, setMetierListe] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [idOffer, setIDOffer] = useState(null);
  const [numberOfResults, setNumberOfResults] = useState(0);
  const validationSchema = yup.object({
    start_exp: yup.number().typeError("Valeur invalide").min(0, "Valeur invalide"),
    end_exp: yup.number().typeError("Valeur invalide").min(0, "Valeur invalide"),
    start_taux: yup.number().typeError("Valeur invalide").min(0, "Valeur invalide"),
    end_taux: yup.number().typeError("Valeur invalide").min(0, "Valeur invalide"),
  });
  const [changedFiltre, setChangedFiltre] = useState({
    metier_ids: [],
    start_exp: "",
    end_exp: "",
    start_taux: "",
    end_taux: "",
    start_date: null,
    end_date: null,
    perimetre: "",
    primes: false,
    panier: false,
  });
  const [index, setIndex] = useState(0);
  useEffect(() => {
    setIsLoading(true);
    Api.get(`${Urls.GET_OFFRE_LIST}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: perPage,
        interimId: iduser,
        ...(changedFiltre?.metier_ids.length > 0 && { metierIds: changedFiltre?.metier_ids }),
        ...(selectButton === "choice1" ? { onlyMatched: 1 } : null),
        ...(changedFiltre?.start_exp !== "" && { experienceYearsWantedMin: changedFiltre?.start_exp }),
        ...(changedFiltre?.end_exp !== "" && { experienceYearsWantedMax: changedFiltre?.end_exp }),
        ...(changedFiltre?.start_taux !== "" && { basicHourlyRateMin: changedFiltre?.start_taux }),
        ...(changedFiltre?.end_taux !== "" && { basicHourlyRateMax: changedFiltre?.end_taux }),
        ...(changedFiltre?.start_date && { startDateMin: changedFiltre?.start_date }),
        ...(changedFiltre?.end_date && { endDateMax: changedFiltre?.end_date }),
        ...(changedFiltre?.perimetre !== "" && { filterPerimeter_radius: changedFiltre?.perimetre }),
        ...(changedFiltre?.primes && { hasBonus: changedFiltre?.primes ? 1 : 0 }),
        ...(changedFiltre?.panier && { hasBaskets: changedFiltre?.panier ? 1 : 0 }),
      },
    })
      .then((res) => {
        console.log("RESULTAT MATCHED offres DATA", res?.data);
        console.log("RESPONES GHERBI", res);
        console.log(res?.data?.offers[0], " offer 1 : matching level");
        setData(res?.data);
        setOffres(res.data?.offers);
        setNumberOfResults(res?.data?.meta?.total);
        console.log(res?.data?.meta?.links, "link pag");
        if (res?.data?.meta?.total === 0 && index === 0) {
          setSelectButton("choice2");
          setIndex(index + 1);
        } else {
          setIsLoading(false);
        }
        if (res?.data?.meta?.total === 0 && selectButton === "choice2") {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setOffres([]);
        setData(null);
      });
  }, [changedFiltre, selectButton]);

  //handle Pagination
  const handlePagination = (link) => {
    setIsLoading(true);
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("RESULTAT MATCHED offres DATA", res?.data);
        console.log("RESPONES GHERBI", res);
        console.log(res?.data?.offers[0], " offer 1 : matching level");
        setData(res?.data);
        setOffres(res.data?.offers);
        setIsLoading(false);
        setNumberOfResults(res?.data?.meta?.total);
        console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setOffres([]);
      });
  };
  //handle postuler
  const [openPostuler, setOpenPostuler] = useState(false);
  const [postulerIDS, setPostulerIDS] = useState([]);
  const handlePostuler = (id, index) => {
    return new Promise((resolve, reject) => {
      Api.post(Urls.POSTULER_OFFRE_ID + iduser + "/offers/" + id + "/apply", null, {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${user?.access_token}` },
      })
        .then((res) => {
          setPostulerIDS(postulerIDS.concat(id));
          setOpenPostuler(false);
          resolve(true);
        })
        .catch((err) => {
          reject(false);
        });
    });
  };
  //HANDLE CLICK OFFER
  const handelClickOffer = (id, index) => {
    setIDOffer(id);
    setOpen(true);
  };
  console.log(selected, "selected items");
  const searchMetier = (value) => {
    Api.get(Urls.GET_METIER_AUTO, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        search: value,
      },
    })
      .then((res) => {
        console.log(res, "search resposne");
        setMetierListe(res?.data?.metiers);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(changedFiltre, "changed filter");
  console.log(data, "this is the data");
  console.log(offres, "offres after update");
  return (
    <Box display="flex" gap="24px" flexDirection="column">
      <ToastContainer />
      <Box
        marginBottom="0px"
        display="flex"
        sx={{
          flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
          justifyContent: { sx: "center", sm: "center", md: "space-between", lg: "space-between" },
          alignItems: { sm: "center", xs: "center", md: "inherit", lg: "inherit" },
          gap: { sm: "10px", xs: "10px", md: "0px", lg: "0px" },
        }}
      >
        <Box>
          <Typography fontSize="28px" fontWeight="700" lineHeight="160%">
            Trouver un job pour :
          </Typography>
          <Typography fontSize="28px" fontWeight="700" lineHeight="160%">
            #{iduser} {prenom} {nom}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" marginBottom="16px" alignItems="center">
          <Button
            variant={selectButton === "choice1" ? "mj_primary_choice_active" : "mj_primary_choice"}
            onClick={() => {
              setChangedFiltre({
                metier_ids: [],
                start_exp: "",
                end_exp: "",
                start_taux: "",
                end_taux: "",
                start_date: null,
                end_date: null,
                perimetre: "",
                primes: false,
                panier: false,
              });
              setPostulerIDS([]);
              setSelectButton("choice1");
            }}
          >
            <img src={lampIcon} alt="lamp-icon" style={{ marginRight: "4px" }} /> Offres suggérées
          </Button>
          <Button variant={selectButton === "choice2" ? "mj_primary_choice_active" : "mj_primary_choice"} onClick={() => setSelectButton("choice2")}>
            <img src={DocumentIcon} alt="lamp-icon" style={{ marginRight: "4px" }} /> Toutes les offres
          </Button>
        </Box>
      </Box>
      {selectButton === "choice1" ? (
        <Box display="flex" justifyContent="center">
          {isLoading === false && (
            <Box sx={{ marginBottom: "24px", width: { sm: "100%", md: "80%", lg: "80%", xs: "100%" } }}>
              {offres?.map((offre, index) => (
                <Box key={offre.id} onClick={() => handelClickOffer(offre.id, index)}>
                  <OffreComponent key={offre.id} handlePostuler={handlePostuler} id={offre.id} postulerIDS={postulerIDS} handelClickOffer={handelClickOffer} matchingLevel={offre?.matching_level} createdAt={offre?.created_at} start_date={offre?.start_date} end_date={offre?.end_date} hourly_rate={offre?.basic_hourly_rate} address={offre?.location_address} offreName={offre?.job_title} posteName={offre?.metier?.name} detail={offre?.tasks_to_do} />
                </Box>
              ))}
              {offres.length > 0 && <Divider sx={{ margin: "24px 0px" }} />}
              {offres?.length === 0 && <Typography textAlign="center">Aucune offre</Typography>}
              {offres?.length > 0 && (
                <Box display="flex" alignItems="center" gap="20px" justifyContent="space-between">
                  <Box flex={1}>
                    {data?.links?.prev && (
                      <>
                        <Button
                          sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                          onClick={() => {
                            handlePagination(data?.links?.prev);
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                          variant="mj_secondary_md"
                        >
                          <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                        </Button>
                        <Button
                          sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                          onClick={() => {
                            handlePagination(data?.links?.prev);

                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                          variant="mj_secondary_md"
                        >
                          <img src={leftArrowIcon} alt="arrow-left" />
                        </Button>
                      </>
                    )}
                  </Box>
                  <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
                    {data?.meta?.links?.map((link, index) => (
                      <>
                        {index !== 0 && index !== data?.meta?.last_page + 1 && (
                          <ButtonSquare
                            onClick={() => {
                              handlePagination(link.url);
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                              });
                            }}
                            action={link?.active ? "number-active" : "number"}
                            number={link.label}
                          />
                        )}
                      </>
                    ))}
                  </Box>
                  <Box flex={1}>
                    {data?.links?.next && (
                      <>
                        <Button
                          sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                          onClick={() => {
                            handlePagination(data?.links?.next);
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                          variant="mj_secondary_md"
                        >
                          Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                        </Button>
                        <Button
                          sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                          onClick={() => {
                            handlePagination(data?.links?.next);
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                          variant="mj_secondary_md"
                        >
                          <img src={arrowRightIcon} alt="arrow-right" />
                        </Button>
                      </>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {isLoading && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between" gap="24px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
          <Box flex={2}>
            <BoxContainer>
              <Formik
                initialValues={{
                  titre: { id: null, name: "" },
                  start_exp: "",
                  end_exp: "",
                  start_taux: "",
                  end_taux: "",
                  start_date: null,
                  end_date: null,
                  perimetre: "",
                  primes: false,
                  panier: false,
                }}
                onSubmit={(values) => {
                  console.log(values);
                }}
                validationSchema={validationSchema}
              >
                {({ values, errors, setFieldValue, touched }) => (
                  <Form>
                    <Box display="flex" flexDirection="column" gap="24px">
                      <Box>
                        <Typography fontSize="20px" lineHeight="32px" fontWeight="700">
                          🔍 Filtres
                        </Typography>
                      </Box>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Métier
                          </Typography>
                        </Box>
                        <Box>
                          <Autocomplete
                            disablePortal
                            fullWidth
                            id="combo-box-demo"
                            blurOnSelect={true}
                            value={null}
                            onChange={(e, value) => {
                              console.log(value);
                              const exist = selected?.filter((select) => select.id === value.id);
                              if (!exist[0]) {
                                setSelected(selected.concat([value]));
                                setChangedFiltre({ ...changedFiltre, metier_ids: [value.id, ...changedFiltre.metier_ids] });
                              }
                              setMetierListe([]);
                            }}
                            options={metierListe}
                            getOptionLabel={(Option) => Option.name}
                            renderInput={(params) => (
                              <TextField
                                sx={{ maxWidth: "100%" }}
                                {...params}
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  if (e.target.value.length > 3) {
                                    searchMetier(e.target.value);
                                  }
                                }}
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img src={RechercheIcon} alt="end" />
                                    </InputAdornment>
                                  ),
                                  endAdornment: null,
                                }}
                                placeholder="Rechercher des métiers..."
                              />
                            )}
                          />
                        </Box>
                        <Box flexWrap="wrap" display="flex" gap="10px" margin="10px 0px 0px 0px">
                          {selected?.map((select) => (
                            <MyChip
                              variant="skill"
                              label={select.name}
                              onDelete={() => {
                                setSelected(selected.filter((sel) => sel.id !== select.id));
                                setChangedFiltre({ ...changedFiltre, metier_ids: changedFiltre.metier_ids.filter((metier) => metier !== select.id) });
                              }}
                            />
                          ))}
                        </Box>
                      </Box>
                      <Divider />
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Expérience
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" gap="16px">
                          <Box flex={1}>
                            <Box display="flex" flexDirection="column">
                              <TextField
                                name="start_exp"
                                fullWidth
                                value={values.start_exp}
                                onChange={(e) => {
                                  setFieldValue("start_exp", e.target.value);
                                  if (errors?.start_exp === null) {
                                    setChangedFiltre({ ...changedFiltre, start_exp: e.target.value });
                                  }
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <img src={AnsIcon} alt="start" />
                                    </InputAdornment>
                                  ),
                                }}
                                placeholder="0"
                              />
                            </Box>{" "}
                            {errors.start_exp ? (
                              <Typography color="red" fontSize="12px">
                                {errors.start_exp}
                              </Typography>
                            ) : null}
                          </Box>
                          <Box>
                            <img src={arrowRightIcon} alt="icon-point" />
                          </Box>
                          <Box flex={1}>
                            <Box display="flex" flexDirection="column">
                              <TextField
                                name="end_exp"
                                fullWidth
                                value={values.end_exp}
                                onChange={(e) => {
                                  setFieldValue("end_exp", e.target.value);
                                  if (!errors.end_exp) {
                                    setChangedFiltre({ ...changedFiltre, end_exp: e.target.value });
                                  }
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <img src={AnsIcon} alt="start" />
                                    </InputAdornment>
                                  ),
                                }}
                                placeholder="0"
                              />
                            </Box>{" "}
                            {errors.end_exp ? (
                              <Typography color="red" fontSize="12px">
                                {errors.end_exp}
                              </Typography>
                            ) : null}
                          </Box>
                        </Box>
                      </Box>
                      <Divider />
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Taux horaire
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" gap="16px">
                          <Box flex={1}>
                            <Box display="flex" flexDirection="column">
                              <TextField
                                name="start_taux"
                                fullWidth
                                value={values.start_taux}
                                onChange={(e) => {
                                  setFieldValue("start_taux", e.target.value);
                                  if (!errors.start_taux) {
                                    setChangedFiltre({ ...changedFiltre, start_taux: e.target.value });
                                  }
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <img src={euroIcon} alt="start" />
                                    </InputAdornment>
                                  ),
                                }}
                                placeholder="0"
                              />
                            </Box>{" "}
                            {errors.start_taux ? (
                              <Typography color="red" fontSize="12px">
                                {errors.start_taux}
                              </Typography>
                            ) : null}
                          </Box>
                          <Box>
                            <img src={arrowRightIcon} alt="icon-point" />
                          </Box>
                          <Box flex={1}>
                            <Box display="flex" flexDirection="column">
                              <TextField
                                name="end_taux"
                                fullWidth
                                value={values.end_taux}
                                onChange={(e) => {
                                  setFieldValue("end_taux", e.target.value);
                                  if (!errors.end_taux) {
                                    setChangedFiltre({ ...changedFiltre, end_taux: e.target.value });
                                  }
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <img src={euroIcon} alt="start" />
                                    </InputAdornment>
                                  ),
                                }}
                                placeholder="0"
                              />
                            </Box>{" "}
                            {errors.end_taux ? (
                              <Typography color="red" fontSize="12px">
                                {errors.end_taux}
                              </Typography>
                            ) : null}
                          </Box>
                        </Box>
                      </Box>
                      <Divider />
                      <Box width="100%">
                        <Box marginBottom="6px" display="flex" gap="4px" alignItems="center">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%">
                            Dates
                          </Typography>
                          <Box>
                            <ButtonSquare
                              onClick={() => {
                                setFieldValue("end_date", null);
                                setFieldValue("start_date", null);
                                setChangedFiltre({ ...changedFiltre, end_date: "", start_date: "" });
                              }}
                              action="close"
                            />
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center" gap="16px">
                          <Box flex={1}>
                            <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                components={{ OpenPickerIcon: CalendarMonthIcon }}
                                name="start_date"
                                value={values.start_date}
                                maxDate={new Date()}
                                onChange={(newValue) => {
                                  setFieldValue("start_date", moment(newValue).format("yyyy-MM-DD"));
                                  setChangedFiltre({ ...changedFiltre, start_date: newValue.toISOString().slice(0, 10) });
                                }}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      fullWidth
                                      type="date"
                                      autoComplete="off"
                                      {...params}
                                      error={false}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "Du",
                                      }}
                                    />
                                  );
                                }}
                              ></DesktopDatePicker>
                            </LocalizationProvider>{" "}
                          </Box>
                          <Box>
                            <img src={arrowRightIcon} alt="icon-point" />
                          </Box>
                          <Box flex={1}>
                            <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                components={{ OpenPickerIcon: CalendarMonthIcon }}
                                name="end_date"
                                value={values.end_date}
                                maxDate={new Date()}
                                onChange={(newValue) => {
                                  setFieldValue("end_date", moment(newValue).format("yyyy-MM-DD"));
                                  setChangedFiltre({ ...changedFiltre, end_date: newValue.toISOString().slice(0, 10) });
                                }}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      fullWidth
                                      type="date"
                                      autoComplete="off"
                                      {...params}
                                      error={false}
                                      inputProps={{
                                        ...params.inputProps,
                                        placeholder: "Au",
                                      }}
                                    />
                                  );
                                }}
                              ></DesktopDatePicker>
                            </LocalizationProvider>{" "}
                          </Box>
                        </Box>
                      </Box>
                      <Divider />
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Adresse
                          </Typography>
                        </Box>
                        <Box>
                          <Autocomplete
                            disablePortal
                            fullWidth
                            id="combo-box-demo"
                            blurOnSelect={true}
                            value={null}
                            onChange={(e, value) => {
                              console.log(value);
                              const exist = selected?.filter((select) => select.id === value.id);
                              if (!exist[0]) {
                                setSelected(selected.concat([value]));
                              }
                              setMetierListe([]);
                            }}
                            options={metierListe}
                            getOptionLabel={(Option) => Option.name}
                            renderInput={(params) => (
                              <TextField
                                sx={{ maxWidth: "100%" }}
                                {...params}
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  if (e.target.value.length > 3) {
                                    searchMetier(e.target.value);
                                  }
                                }}
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img src={RechercheIcon} alt="end" />
                                    </InputAdornment>
                                  ),
                                  endAdornment: null,
                                }}
                                placeholder="Rechercher des villes..."
                              />
                            )}
                          />
                        </Box>
                      </Box>
                      <Divider />
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Périmètre
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" gap="16px">
                          <Box flex={1}>
                            <Box display="flex" flexDirection="column">
                              <TextField
                                name="perimetre"
                                fullWidth
                                value={values.perimetre}
                                onChange={(e) => {
                                  setFieldValue("perimetre", e.target.value);
                                  setChangedFiltre({ ...changedFiltre, perimetre: e.target.value });
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <img src={euroIcon} alt="start" />
                                    </InputAdornment>
                                  ),
                                }}
                                placeholder="0"
                              />
                            </Box>{" "}
                          </Box>
                          <Box></Box>
                          <Box flex={1}></Box>
                        </Box>
                      </Box>
                      <Divider />
                      <Box>
                        <Box width="100%">
                          <FormControlLabel
                            onChange={(e) => {
                              setFieldValue("primes", e.target.checked);
                              setChangedFiltre({ ...changedFiltre, primes: e.target.checked });
                            }}
                            control={<Checkbox checked={values.primes} />}
                            label="Primes"
                          />
                        </Box>
                        <Box width="100%">
                          <FormControlLabel
                            onChange={(e) => {
                              setFieldValue("panier", e.target.checked);
                              setChangedFiltre({ ...changedFiltre, panier: e.target.checked });
                            }}
                            control={<Checkbox checked={values.panier} />}
                            label="Panier"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </BoxContainer>
          </Box>
          <Box flex={5}>
            {isLoading === false && (
              <Box sx={{ marginBottom: "20px", marginLeft: "0px" }} maxWidth="lg">
                {offres?.map((offre) => (
                  <Box key={offre.id}>
                    <OffreComponent key={offre.id} handlePostuler={handlePostuler} id={offre.id} postulerIDS={postulerIDS} handelClickOffer={handelClickOffer} matchingLevel={offre?.matching_level} createdAt={offre?.created_at} start_date={offre?.start_date} end_date={offre?.end_date} hourly_rate={offre?.basic_hourly_rate} address={offre?.location_address} offreName={offre?.job_title} posteName={offre?.metier?.name} detail={offre?.tasks_to_do} />
                  </Box>
                ))}
                {offres.length > 0 && <Divider sx={{ margin: "24px 0px" }} />}
                {offres?.length === 0 && <Typography textAlign="center">Aucune offre</Typography>}
                {offres?.length > 0 && (
                  <Box display="flex" alignItems="center" gap="20px" justifyContent="space-between">
                    <Box flex={1}>
                      {data?.links?.prev && (
                        <>
                          <Button
                            sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                            onClick={() => {
                              handlePagination(data?.links?.prev);
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                              });
                            }}
                            variant="mj_secondary_md"
                          >
                            <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                          </Button>
                          <Button
                            sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                            onClick={() => {
                              handlePagination(data?.links?.prev);
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                              });
                            }}
                            variant="mj_secondary_md"
                          >
                            <img src={leftArrowIcon} alt="arrow-left" />
                          </Button>
                        </>
                      )}
                    </Box>
                    <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center" flexWrap="wrap">
                      {data?.meta?.links?.map((link, index) => (
                        <>
                          {index !== 0 && index !== data?.meta?.last_page + 1 && (
                            <ButtonSquare
                              onClick={() => {
                                handlePagination(link.url);
                                window.scrollTo({
                                  top: 0,
                                  behavior: "smooth",
                                });
                              }}
                              action={link?.active ? "number-active" : "number"}
                              number={link.label}
                            />
                          )}
                        </>
                      ))}
                    </Box>
                    <Box flex={1}>
                      {data?.links?.next && (
                        <>
                          <Button
                            sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                            onClick={() => {
                              handlePagination(data?.links?.next);
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                              });
                            }}
                            variant="mj_secondary_md"
                          >
                            Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                          </Button>
                          <Button
                            sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                            onClick={() => {
                              handlePagination(data?.links?.next);
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                              });
                            }}
                            variant="mj_secondary_md"
                          >
                            <img src={arrowRightIcon} alt="arrow-right" />
                          </Button>
                        </>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            )}
            {isLoading && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={modalStyle2}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" gap="10px" alignItems="center">
                <ButtonSquare onClick={() => setOpen(false)} action="close" />

                <Typography variant="text_xxl" fontWeight="700">
                  Offre #{idOffer}
                </Typography>
              </Box>
              {postulerIDS?.includes(idOffer) ? (
                <MyChip variant="skill" label="Vous avez postulé" />
              ) : (
                <Button variant="mj_primary_md" onClick={() => setOpenPostuler(true)}>
                  Postuler
                </Button>
              )}
            </Box>
            <DetailOffre id={idOffer} />
          </BoxContainer>
        </Box>
      </Modal>
      <Modal
        open={openPostuler}
        onClose={() => {
          setOpenPostuler(false);
        }}
      >
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box display="flex" justifyContent="end" alignItems="end">
              <ButtonSquare onClick={() => setOpenPostuler(false)} action="close" />
            </Box>
            <Typography marginBottom="16px" variant="text_xxl" fontWeight="700">
              Voulez-vous postuler pour ce poste ?
            </Typography>
            <Box display="flex" flexDirection="column" gap="22px" marginBottom="46px">
              <Box marginBottom="10px" display="flex" justifyContent="center">
                <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "160%", textAlign: "center" }}>Êtes-vous sûr(e) de vouloir postuler pour ce poste ?</Typography>
              </Box>
              <Box>
                <FormControlLabel
                  onChange={(e) => {
                    setCheckedPostuler(e.target.checked);
                  }}
                  control={<Checkbox checked={checkedPostuler} />}
                  label="Je possède les certifications demandées."
                />
              </Box>
            </Box>
            <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
              <Button
                onClick={() => {
                  setOpenPostuler(false);
                }}
                variant="mj_secondary_md"
              >
                Annuler
              </Button>
              <Button disabled={!checkedPostuler} onClick={() => handlePostuler(idOffer)} variant="mj_primary_md">
                Envoyer
              </Button>{" "}
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default TrouverDuTravail;
