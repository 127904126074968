import React, { useEffect, useState } from "react";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { chipSelector } from "../../utils/chipSelector";
import ButtonDataGrid from "../../components/ui/ButtonDataGrid";
import { Box, TextField, Button, Typography, Checkbox, IconButton, Select, MenuItem, FormControl, OutlinedInput, InputLabel, FormControlLabel, Alert, Modal } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { colors } from "../../theme/Colors";
import leftArrowIcon from "../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../imgs/imgV2/arrowRightBlavk.svg";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import { Formik, Form, Field, useFormik, ErrorMessage } from "formik";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import axios from "axios";
import { Urls } from "../../api/Urls";
import { id } from "date-fns/locale";
import * as yup from "yup";
import { json, useNavigate } from "react-router-dom";
import { ArrowBack, ArrowBackIosOutlined, ArrowForward, ArrowLeft, ArrowRight, CheckBox, ErrorSharp, Flag } from "@mui/icons-material";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { ToastContainer, toast } from "react-toastify";

function Entreprises() {
  const navigate = useNavigate();
  const validationSchema = yup.object().shape({
    siret: yup.number("ce champ doit comporter un nombre").typeError("ce champ doit comporter un nombre").max(99999999999999, "un maximum de 14 chiffres"),
    rib: yup.number("ce champ doit comporter un nombre").typeError("ce champ doit comporter un nombre").max(999999999999999999999999999, "un maximum de 27 chiffres"),
    email: yup.string("ce champ doit etre un texte").email("ce champ doit etre un email"),
  });
  const columns = [
    { field: "id", headerName: "ID", width: 90, headerClassName: "grid-header" },
    {
      field: "name",
      headerName: "Entreprise",
      width: 150,
      renderCell: (params) => {
        if (params.row.name === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.name;
        }
      },
    },
    {
      field: "email",
      headerName: "Email de l'entreprise",
      width: 180,
      renderCell: (params) => {
        if (params.row.email === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.email;
        }
      },
    },
    {
      field: "code_ape",
      headerName: "Code APE",
      width: 100,
      align: "center",
      renderCell: (params) => {
        if (params.row.code_ape === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.code_ape;
        }
      },
    },
    {
      field: "forme_juridique_name",
      headerName: "Forme Juridique",
      width: 150,
      align: "center",
      renderCell: (params) => {
        if (params.row.forme_juridique_name === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.forme_juridique_name;
        }
      },
    },
    {
      field: "rib",
      headerName: "RIB",
      width: 150,
      renderCell: (params) => {
        if (params.row.rib === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.rib;
        }
      },
    },
    {
      field: "siret",
      headerName: "Siret",
      width: 150,
      renderCell: (params) => {
        if (params.row.siret === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.siret;
        }
      },
    },
    {
      field: "agency_name",
      headerName: "Agence",
      width: 150,
      renderCell: (params) => {
        if (params.row.agency_name === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.agency_name;
        }
      },
    },
    {
      field: "status",
      headerName: "Etat",
      description: "This column has a value getter and is not sortable.",
      width: 110,
      align: "center",
      renderCell: (params) => {
        if (params.row.user_verification_data_status !== null) return chipSelector(params.row.user_verification_data_status);
        else return <Typography>-</Typography>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",
      align: "start",
      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" gap="0.2rem" alignItems="center">
            <ButtonDataGrid
              action={"accept"}
              onClick={() => {
                setOpen(true);
                setAction("valider");
                setId(params.row);
              }}
            />
            <ButtonDataGrid
              action={"refuse"}
              onClick={() => {
                setOpen(true);
                setAction("refuser");
                setId(params.row);
              }}
            />
            <ArrowCircleRightIcon action={"details"} sx={{ cursor: "pointer", color: `${colors.mjGreenOutline}`, fontSize: 40 }} onClick={() => navigate(`/admin/gestion-des-entreprises/details/${params.row.id}`, { state: { status: params.row.status, user_id: params.row.user_id } })} />
          </Box>
        );
      },
      width: 160,
      valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
  ];

  const [finalValues, setFinalValues] = useState();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [id, setId] = useState(null);
  const { user } = useAuthContext();
  const [action, setAction] = useState("");
  const [open, setOpen] = useState(false);
  const [change, setChange] = useState(0);
  const [openMultiple, setOpenMultiple] = useState(false);
  const [multipleActions, setMultipleActions] = useState("");
  const access_token = user?.access_token;
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectedIDs, setSelectedIDs] = useState(null);
  const [openParams, setOpenParams] = React.useState(false);
  const [rows, setRows] = useState([]);
  const [data, setData] = useState({});
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [status, setStatus] = useState([]);
  const [rowsPerPage, setRowsPer] = useState(10);
  const [total, seTotal] = useState(0);
  const [formes, setFormes] = useState([]);
  const formik = useFormik({
    initialValues: {
      id: "",
      rib: "",
      siret: "",
      email: "",
      code_ape: "",
      name: "",
      forme_juridique_id: "",
      checkedAtt: false,
      checkedValid: false,
      checkedRef: false,
      agence: "",
    },
    onSubmit: () => {
      setPage(1);
      setFinalValues(formik.values);
    },
    validationSchema: validationSchema,
  });

  //get forme juridique
  useEffect(() => {
    Api.get(Urls.GET_FORME_JURIDIQUE, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res.data.data, "forme jur");
        setFormes(res?.data?.forme_juridiques);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    if (rowsPerPage && page) {
      setLoading(true);
      let forme_juridique_id = null;
      if (formik?.values?.forme_juridique_id !== "") {
        const forme_jur = formes.filter((form) => form.name === formik.values.forme_juridique_id);
        forme_juridique_id = forme_jur[0].id;
        console.log(forme_juridique_id);
      }
      Api.get(Urls.GET_LIST_ENTREPRISE + `?perPage=${25}`, {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          ...(formik.values.id !== "" && { id: formik.values.id }),
          ...(formik.values.name !== "" && { name: formik.values.name }),
          ...(formik.values.siret !== "" && { siret: formik.values.siret }),
          ...(formik.values.code_ape !== "" && { code_ape: formik.values.code_ape }),
          ...(formik.values.email !== "" && { email: formik.values.email }),
          ...(forme_juridique_id !== null && { forme_juridique_id: forme_juridique_id }),
          ...(formik.values.rib !== "" && { rib: formik.values.rib }),
          ...(formik.values.agence !== "" && { agencyId: formik.values.agence }),

          ...(status.length !== 0 && { status: status }),
        },
      })
        .then((res) => {
          console.log(res.data);
          setRows(res?.data?.enterprises);
          setData(res.data);
          setLoading(false);
          seTotal(res?.data?.meta?.total);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [formik.values, change]);
  //handle Pagination
  const handlePagination = (link) => {
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("RESULTAT MATCHED offres DATA", res?.data);
        console.log("RESPONES GHERBI", res);
        setRows(res.data.enterprises);
        setData(res.data);
        setLoading(false);
        console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        console.log(err);
        setRows([]);
      });
  };
  //animate open params
  const animateOpen = {
    transform: "rotate(90deg)",
    transition: "0.25s",
  };
  const animateClose = {
    transform: "rotate(0deg)",
    transition: "0.25s",
  };
  const customFooter = () => {
    return <Box></Box>;
  };
  const AcceptUser = (id) => {
    Api.patch(
      Urls.PATCH_STATUS_ENTREPRISE,
      {
        userId: id,
        verification_data_status: "VALID",
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res.data);
        setFinalValues([""]);
        setOpen(false);
        toast.success(res?.data?.messages);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const deleteUser = (id) => {
    Api.patch(
      Urls.PATCH_STATUS_ENTREPRISE,
      {
        user_ids: [id],
        verification_data_status: "NOT_VALID",
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res.data);
        setFinalValues([""]);
        setOpen(false);
        toast.success(res?.data?.messages);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err.messages);
        toast.error(err?.response?.data?.message);
      });
  };
  //accept multiple users
  const handleAccept = () => {
    let user_ids = [];
    for (let index = 0; index < rows.length; index++) {
      const element1 = rows[index];
      for (let i = 0; i < selectedIDs.length; i++) {
        const element2 = selectedIDs[i];
        if (element1.id === element2) {
          user_ids.push(element1.user_id);
        }
      }
    }
    Api.patch(
      Urls.PATCH_STATUS_ENTREPRISE,
      {
        user_ids: user_ids,
        verification_data_status: "VALID",
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res.data);
        setFinalValues([""]);
        setOpenMultiple(false);
        toast.success(res?.data?.messages);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err);
        setOpenMultiple(false);
        toast.error(err?.response?.data?.message);
      });
  };
  // remove multiple users
  const handleDelete = () => {
    let user_ids = [];
    for (let index = 0; index < rows.length; index++) {
      const element1 = rows[index];
      for (let i = 0; i < selectedIDs.length; i++) {
        const element2 = selectedIDs[i];
        if (element1.id === element2) {
          user_ids.push(element1.user_id);
        }
      }
    }
    Api.patch(
      Urls.PATCH_STATUS_ENTREPRISE,
      {
        user_ids: user_ids,
        verification_data_status: "NOT_VALID",
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res.data);
        setFinalValues([""]);
        setOpenMultiple(false);
        setChange(!change);
        toast.success(res?.data?.messages);
      })
      .catch((err) => {
        console.log(err);
        setOpenMultiple(false);
        toast.error(err?.response?.data?.message);
      });
  };
  const [agences, setAgence] = useState([]);
  useEffect(() => {
    setLoading(true);
    Api.get(Urls.GET_AGENCES + `?perPage=${25}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setAgence(res?.data?.agencies);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Box>
      <ToastContainer />
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography noWrap variant="h4" component="h1">
          Gestion des entreprises
        </Typography>
      </Box>

      {/* Form Section */}
      <form onSubmit={formik.handleSubmit}>
        <Box className="filter" display="flex" flexDirection="column" gap="1rem">
          <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column" } }}>
            <Box>
              <Typography noWrap mr marginTop="0.45rem" fontWeight="600">
                Filtre :
              </Typography>
            </Box>

            {/*Search fields  */}
            <Box display="flex" alignItems="center" marginRight="1rem" mb>
              <label style={{ marginRight: "0.5rem" }} htmlFor="entreprise">
                Nom de l'entreprise
              </label>
              <TextField onChange={formik.handleChange} name="name" placeholder="entreprise" />
            </Box>
            <Box display="flex" alignItems="center" marginRight="1rem" mb>
              <label style={{ marginRight: "0.5rem" }} htmlFor="entreprise">
                ID de l'entreprise
              </label>
              <TextField onChange={formik.handleChange} name="id" placeholder="Id entreprise" />
            </Box>
          </Box>

          {/* Checkboxes */}
          <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column" }, gap: "0.5rem", marginBottom: "0.5rem" }}>
            <Box display="flex" alignItems="center">
              <label htmlFor="state">Etat : </label>
            </Box>

            <FormControlLabel
              label="En attente"
              control={
                <Checkbox
                  checked={formik.values.checkedAtt}
                  onChange={(e) => {
                    formik.setFieldValue("checkedAtt", e.target.checked);
                    e.target.checked === true ? setStatus((status) => status.concat(["PENDING"])) : setStatus(status.filter((status) => status !== "PENDING"));
                  }}
                />
              }
            />
            <FormControlLabel
              label="validé"
              control={
                <Checkbox
                  checked={formik.values.checkedValid}
                  onChange={(e) => {
                    formik.setFieldValue("checkedValid", e.target.checked);
                    e.target.checked === true ? setStatus((status) => status.concat(["VALID"])) : setStatus(status.filter((status) => status !== "VALID"));
                  }}
                />
              }
            />
            <FormControlLabel
              label="Refusé"
              control={
                <Checkbox
                  checked={formik.values.checkedRef}
                  onChange={(e) => {
                    formik.setFieldValue("checkedRef", e.target.checked);
                    e.target.checked === true ? setStatus((status) => status.concat(["NOT_VALID"])) : setStatus(status.filter((status) => status !== "NOT_VALID"));
                  }}
                />
              }
            />
          </Box>
          <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={open} onClose={() => setOpen(false)}>
            <Box width="350px" sx={{ backgroundColor: "white", borderRadius: "10px" }}>
              <Box color="white" padding="1rem" display="flex" sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", backgroundColor: `${colors.mj_red}` }} justifyContent="center" alignItems="center">
                {action} le/les compte entreprise
              </Box>
              <Box>
                <Typography display="inline-block" margin="1rem" textAlign="center">
                  Voulez vous vraiment{" "}
                  <Typography display="inline-block" fontWeight="bold">
                    {action} le/les compte entreprise
                  </Typography>{" "}
                </Typography>
              </Box>
              {success ? (
                <Alert sx={{ margin: "0rem 0rem 1rem 0rem", textAlign: "center" }} severity="success">
                  {success}
                </Alert>
              ) : null}
              {error ? <Alert severity="error">{error}</Alert> : null}
              <Box margin="0 auto" display="flex" gap="1rem" alignItems="center" justifyContent="center">
                <Box onClick={() => (action === "valider" ? AcceptUser(id?.user_id) : deleteUser(id?.user_id))} margin="1rem 0rem" display="flex" alignItems="center" justifyContent="center">
                  <Button variant="mj_primary_md">{action}</Button>
                </Box>
                <Box onClick={() => setOpen(false)} margin="1rem 0rem" display="flex" alignItems="center" justifyContent="center">
                  <Button variant="mj_secondary_md">Annuler</Button>
                </Box>
              </Box>
            </Box>
          </Modal>
          <Modal sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={openMultiple} onClose={() => setOpenMultiple(false)}>
            <Box width="350px" sx={{ backgroundColor: "white", borderRadius: "10px" }}>
              <Box color="white" padding="1rem" display="flex" sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", backgroundColor: `${colors.mj_red}` }} justifyContent="center" alignItems="center">
                {multipleActions} le/les compte entreprise
              </Box>
              <Box>
                <Typography display="inline-block" margin="1rem" textAlign="center">
                  Voulez vous vraiment{" "}
                  <Typography display="inline-block" fontWeight="bold">
                    {multipleActions} le/les compte entreprise
                  </Typography>{" "}
                </Typography>
              </Box>
              {success ? (
                <Alert sx={{ margin: "0rem 0rem 1rem 0rem", textAlign: "center" }} severity="success">
                  {success}
                </Alert>
              ) : null}
              {error ? <Alert severity="error">{error}</Alert> : null}
              <Box margin="0 auto" display="flex" gap="1rem" alignItems="center" justifyContent="center">
                <Box onClick={() => (multipleActions === "valider" ? handleAccept(id?.id) : handleDelete(id?.id))} margin="1rem 0rem" display="flex" alignItems="center" justifyContent="center">
                  <Button variant="mj_primary_md">{multipleActions}</Button>
                </Box>
                <Box onClick={() => setOpenMultiple(false)} margin="1rem 0rem" display="flex" alignItems="center" justifyContent="center">
                  <Button variant="mj_secondary_md">Annuler</Button>
                </Box>
              </Box>
            </Box>
          </Modal>
          <Typography fontWeight="bold">Filtres avancés : </Typography>
          <Box alignItems="center" marginBottom="1rem" gap="1rem" display="flex" flexWrap="wrap">
            <Box alignItems="center" gap="0.3rem" display="flex" flexDirection="column">
              <Box alignItems="center" gap="0.3rem" display="flex">
                <label htmlFor="email">Email entreprise :</label>
                <TextField error={formik?.errors?.email ? true : false} placeholder="email entreprise" name="email" onChange={formik.handleChange} />
              </Box>
            </Box>
            <Box alignItems="center" gap="0.3rem" display="flex">
              <label htmlFor="code_ape">Code ape :</label>
              <TextField placeholder="code ape" name="code_ape" onChange={formik.handleChange} />
            </Box>
            <Box alignItems="center" gap="0.3rem" display="flex">
              <label htmlFor="forme_juridique_id">Forme juridique : </label>
              <FormControl sx={{ maxWidth: "170px", minWidth: "170px", margin: "0.3rem 0rem 0rem 0rem" }}>
                {formik.values.forme_juridique_id ? null : <InputLabel>Forme Juridique</InputLabel>}
                <Select placeholder="Forme juridique" fullWidth value={formik.values.forme_juridique_id} onChange={(e) => formik.setFieldValue("forme_juridique_id", e.target.value)} input={formik.values.forme_juridique_id ? null : <OutlinedInput sx={{ color: "black" }} label="forme juridique id s" />}>
                  <MenuItem key={0} value="">
                    Aucun Filtre
                  </MenuItem>
                  {formes.map((form) => (
                    <MenuItem key={form.id} value={form.name}>
                      {form.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box gap="0.3rem" display="flex" flexDirection="column">
              <Box alignItems="center" gap="0.3rem" display="flex">
                <label htmlFor="rib">Rib : </label>
                <TextField error={formik.errors.rib ? true : false} placeholder="rib" name="rib" onChange={formik.handleChange} />
              </Box>
              <Typography color="red">{formik?.errors?.rib ? formik.errors.rib : null}</Typography>
            </Box>
            <Box alignItems="center" gap="0.3rem" display="flex" flexDirection="column">
              <Box alignItems="center" gap="0.3rem" display="flex">
                <label htmlFor="siret">Siret : </label>
                <TextField error={formik.errors.siret ? true : false} placeholder="siret" name="siret" onChange={formik.handleChange} />
              </Box>
              <Typography color="red">{formik?.errors?.siret ? formik?.errors?.siret : null}</Typography>
            </Box>
            <Box alignItems="center" gap="0.3rem" display="flex">
              <label htmlFor="agence"> Agence : </label>
              <FormControl sx={{ maxWidth: "170px", minWidth: "170px", margin: "0.3rem 0rem 0rem 0rem" }}>
                {formik.values.agence ? null : <InputLabel>Agence </InputLabel>}
                <Select placeholder="Forme juridique" fullWidth value={formik.values.agence} onChange={(e) => formik.setFieldValue("agence", e.target.value)} input={formik.values.agence ? null : <OutlinedInput sx={{ color: "black" }} label="agence ..." />}>
                  <MenuItem key={0} value="">
                    Aucun Filtre
                  </MenuItem>
                  {agences.map((agence) => (
                    <MenuItem key={agence.id} value={agence.id}>
                      {agence.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
        {/* End form section */}

        {/* Actions Section */}
        <Box className="actions">
          <Box display="flex" alignItems="left" gap="0.5rem" mb sx={{ flexDirection: { md: "row", xs: "column" } }}>
            <Typography noWrap mr marginTop="0.4rem" fontWeight="600">
              Actions :
            </Typography>
            {/*
            <Box display="flex" gap="1rem">
              <Button
                onClick={() => {
                  setMultipleActions("valider");
                  setOpenMultiple(true);
                }}
                variant="mj_primary_md"
              >
                Valider les comptes
              </Button>
              
            </Box>
              */}
            <Box display="flex" gap="1rem">
              <Button
                onClick={() => {
                  setMultipleActions("refuser");
                  setOpenMultiple(true);
                }}
                variant="mj_secondary_md"
              >
                Refuser les comptes
              </Button>
            </Box>
          </Box>
        </Box>
      </form>

      {/* Data grid section */}
      {/* Data grid container (styles imported from DataGridHeaderContainer) */}
      <Box marginBottom="24px" sx={DataGridHeaderStyle}>
        <DataGrid
          // sx={{ maxWidth: "1300px" }}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          onSelectionModelChange={(ids) => {
            const selection = new Set(ids);
            setSelectedIDs(ids);
            const selectedRows = rows.filter((row) => selection.has(row.id));
            setSelectedRows(selectedRows);
          }}
          onCellClick={(e) => {
            console.log(e.colDef);
            if (e.field === "action") {
              setTimeout(() => {
                setFinalValues([""]);
              }, 500);
            }
          }}
          rows={rows}
          columns={columns}
          pageSize={rowsPerPage}
          checkboxSelection
          rowCount={total}
          loading={loading}
          page={page - 1}
          rowsPerPageOptions={[10, 30, 60]}
          onPageSizeChange={(size) => {
            setRowsPer(size);
          }}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
              labelRowsPerPage: `Nombre de lignes par page :`,
            },
          }}
          disableSelectionOnClick
          onPageChange={(page) => {
            setPage(page + 1);
          }}
          experimentalFeatures={{ newEditingApi: true }}
          paginationMode="server"
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
            Footer: customFooter,
          }}
        />
      </Box>
      {rows?.length > 0 && (
        <Box marginBottom="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
          <Box flex={1}>
            {data?.links?.prev && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={leftArrowIcon} alt="arrow-left" />
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
            {data?.meta?.links?.map((link, index) => (
              <>
                {index !== 0 && index !== data?.meta?.last_page + 1 && (
                  <ButtonSquare
                    onClick={() => {
                      handlePagination(link.url);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action={link?.active ? "number-active" : "number"}
                    number={link.label}
                  />
                )}
              </>
            ))}
          </Box>
          <Box flex={1}>
            {data?.links?.next && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={arrowRightIcon} alt="arrow-right" />
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Entreprises;
