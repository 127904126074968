import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Divider, Table } from "@mui/material";
import MjLabel from "../../../components/ui/MjLabel";
import MjErrorText from "../../../components/ui/MjErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import styled from "@emotion/styled";
import CircularProgress from "@mui/material/CircularProgress";
import leftArrowIcon from "../../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../../imgs/imgV2/arrowRightBlavk.svg";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";

function HistoriqueLogins({ idAdmin }) {
  const params = useParams();
  // console.log("PARAMS = ", params);
  const location = useLocation();
  // console.log("LOCATION = ", location);
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [succes, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [agencies, setAgencies] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [searchedAgency, setSearchedAgency] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [searchedRole, setSearchedRole] = useState("");
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState();
  const [logs, setLogs] = useState([]);

  //table style
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#FAFAFB",
      color: `#0D173A`,
      fontSize: 12,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: `#666D83`,
      fontWeight: "500",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    cursor: "pointer",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "6px",
  });

  //get admin
  useEffect(() => {
    setLoading(true);
    Api.get(Urls.GET_LISTE_ADMIN + `/${idAdmin}/loginLogs`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("LOGS =====>", res);
        setLogs(res?.data?.login_logs);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  //handle Pagination
  const handlePagination = (link) => {
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("RESULTAT MATCHED offres DATA", res?.data);
        console.log("RESPONES GHERBI", res);
        setData(res?.data);
        setLogs(res.data?.login_logs);
        console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        console.log(err);
        setLogs([]);
      });
  };

  return (
    <Box>
      <ToastContainer />
      {/* Form Section */}
      <Box>
        {/* <pre>{JSON.stringify(logs, null, 2)}</pre> */}

        {/* liste */}
        {!loading ? (
          <TableContainer sx={{ borderRadius: "6px", border: `1px solid #DEDFE4`, maxWidth: "600px" }}>
            <StyledTable aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell width="200px">ID connexion</StyledTableCell>
                  <StyledTableCell width="250px">Date</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs?.map((log, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">
                      <Typography fontWeight="600" color="black" fontSize="14px">
                        #{log?.id}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography fontWeight="400" color="#575E76" fontSize="14px">
                        {moment(log?.date).format("yyyy MMMM DD")}
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        ) : (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {/* pagination */}
        {logs.length > 0 && <Divider sx={{ margin: "24px 0px" }} />}
        {logs?.length === 0 && !loading && <Typography textAlign="center">Aucune facture</Typography>}
        {logs?.length > 0 && (
          <Box marginBottom="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
            <Box flex={1}>
              {data?.links?.prev && (
                <>
                  <Button
                    sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                    onClick={() => {
                      handlePagination(data?.links?.prev);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    variant="mj_secondary_md"
                  >
                    <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                  </Button>
                  <Button
                    sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                    onClick={() => {
                      handlePagination(data?.links?.prev);

                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    variant="mj_secondary_md"
                  >
                    <img src={leftArrowIcon} alt="arrow-left" />
                  </Button>
                </>
              )}
            </Box>
            <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
              {data?.meta?.links?.map((link, index) => (
                <>
                  {index !== 0 && index !== data?.meta?.last_page + 1 && (
                    <ButtonSquare
                      onClick={() => {
                        handlePagination(link.url);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      action={link?.active ? "number-active" : "number"}
                      number={link.label}
                    />
                  )}
                </>
              ))}
            </Box>
            <Box flex={1}>
              {data?.links?.next && (
                <>
                  <Button
                    sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                    onClick={() => {
                      handlePagination(data?.links?.next);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    variant="mj_secondary_md"
                  >
                    Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                  </Button>
                  <Button
                    sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                    onClick={() => {
                      handlePagination(data?.links?.next);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    variant="mj_secondary_md"
                  >
                    <img src={arrowRightIcon} alt="arrow-right" />
                  </Button>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default HistoriqueLogins;
