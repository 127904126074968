import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, Checkbox, IconButton, FormControlLabel, Radio, RadioGroup, FormLabel, FormControl, Modal, Alert, MenuItem, Select, OutlinedInput, InputLabel, InputAdornment, Autocomplete } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { DatePicker } from "@mui/x-date-pickers";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ArrowBack, ArrowBackIosOutlined, ArrowForward, ArrowLeft, ArrowRight, CheckBox, ErrorSharp, FastRewind, Flag } from "@mui/icons-material";
import { colors } from "../../theme/Colors";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import frLocale from "date-fns/locale/fr";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import leftArrowIcon from "../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../imgs/imgV2/arrowRightBlavk.svg";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Formik, Form, Field, useFormik } from "formik";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import RechercheIcon from "../../imgs/imgV2/RechercheIcon.svg";

import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import ButtonDataGrid from "../../components/ui/ButtonDataGrid";
import { chipSelector } from "../../utils/chipSelector";
import "moment/locale/fr";

import { useAuthContext } from "../../hooks/useAuthContext";
import axios from "axios";
import Api from "../../api/Api";
import moment from "moment";
import { Urls } from "../../api/Urls";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonDataGridSquare from "../../components/ui/ButtonDataGridSquare";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { modalStyle } from "../../theme/ModalStyle";
import BoxContainer from "../../components/ui/BoxContainer";
import MyTextField from "../../components/ui/MyTextField";
import MyDPFullDate from "../../components/ui/MyDPFullDate";

function Factures() {
  const [id, setId] = useState(null);
  const [openModify, setOpenModify] = useState(false);
  const [rowsPerPage, setRowsPer] = useState(10);
  const [initialValuesModify, setInitialValuesModify] = useState({});
  const columns = [
    { field: "number", headerName: "N° fiche de paie", width: 150, headerClassName: "grid-header" },
    {
      field: "user",
      headerName: "Intérimaire",
      width: 150,
      renderCell: (params) => {
        return (params.row.user.first_name ? params.row.user.first_name : "-") + " " + (params.row.user.last_name ? params.row.user.last_name : "-");
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      renderCell: (params) => {
        return moment(params.row.date).format("DD MMMM yyyy");
      },
    },
    {
      field: "montant",
      headerName: "Montant",
      width: 80,
      renderCell: (params) => {
        return params.row.amount ? params.row.amount + "€" : "-";
      },
    },
    {
      field: "coffreo_oid",
      headerName: "Coffreo oid",
      width: 250,
      renderCell: (params) => {
        return params.row.coffreo_oid ? params.row.coffreo_oid : "-";
      },
    },
    {
      field: "status",
      headerName: "Etat",
      description: "This column has a value getter and is not sortable.",
      width: 150,
      align: "center",
      renderCell: (params) => {
        return chipSelector(params.row.status === "PAYED" ? "paidF" : "unpaidF");
      },
    },
    {
      field: "action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",
      align: "center",

      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" gap="0.2rem" alignItems="center">
            <ButtonSquare
              action="modify"
              onClick={() => {
                setOpenModify(true);
                setInitialValuesModify({
                  montant: params.row.amount ? params.row.amount : "",
                  status: params.row.status ? params.row.status : "PAYED",
                  id_user: params.row.user?.id ? params.row.user.id : "",
                  number: params.row.number ? params.row.number : "",
                  date: params.row.date ? params.row.date : "",
                  interimaire: {
                    id_user: params.row.user?.id ? params.row.user.id : "",
                    first_name: params.row.user?.first_name ? params.row.user.first_name : "",
                    last_name: params.row.user?.last_name ? params.row.user.last_name : "",
                  },
                  coffreo_oid: params.row.coffreo_oid ? params.row.coffreo_oid : "",
                  id: params.row.id ? params.row.id : "",
                });
              }}
            />
            <ButtonDataGridSquare
              action={"download"}
              onClick={() => {
                const row = rows.filter((row) => row?.id === params?.row?.id);
                window.open(row[0]?.url_file, "_blank");
              }}
            />
          </Box>
        );
      },
      width: 250,
      valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
  ];

  const { user } = useAuthContext();
  const navigate = useNavigate();
  const access_token = user?.access_token;
  const [total, setTotal] = useState(0);
  const [loadin, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [change, setChange] = useState(0);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [data, setData] = useState({});
  const location = useLocation();
  const nomInterim = location?.state?.nom ? location?.state?.nom : "";
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [openParams, setOpenParams] = React.useState(false);
  const [status, setStatus] = useState([]);
  const [initialValues, setInitialValues] = useState({
    interimaire: nomInterim,
    fiche: "",
    datemin: "",
    montant: "",
    datemax: "",
    status: "",
    checkedPay: false,
    checkedImp: false,
  });
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
  });
  const validationSchema = Yup.object().shape({
    montant: Yup.number().typeError("Valeur invalide").required("Ce champ est obligatoire"),
    status: Yup.string().required("Ce champ est obligatoire"),
    date: Yup.date().required("Ce champ est obligatoire"),
    number: Yup.number("Valeur invalide").positive("Valeur invalide").required("Ce champ est obligatoire"),
    id_user: Yup.number("Valeur invalide").positive("Valeur invalide").required("Ce champ est obligatoire"),
  });
  useEffect(() => {
    Api.get(Urls.GET_LISTE_FP + `?perPage=${25}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(formik.values.interimaire !== "" && { search: formik.values.interimaire }),
        ...(formik.values.datemin !== null && formik.values.datemin !== "" && { date: moment(formik.values.datemin).format().split("T")[0] }),
        ...(formik.values.fiche !== "" && { number: formik.values.fiche }),
        ...(formik.values.montant !== "" && { amount: formik.values.montant }),
        ...(status !== [] && { status: status }),
      },
    })
      .then((res) => {
        console.log(res?.data?.payslips);
        setRows(res?.data?.payslips ? res?.data?.payslips : []);
        setData(res.data);
        setLoading(false);
        setTotal(res?.data?.meta?.total);
      })
      .catch((err) => {
        console.log(err);
        setRows([]);
      });
  }, [change, formik.values]);
  //animate open params
  const animateOpen = {
    transform: "rotate(90deg)",
    transition: "0.25s",
  };
  const animateClose = {
    transform: "rotate(0deg)",
    transition: "0.25s",
  };
  //handle Pagination
  const handlePagination = (link) => {
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("RESULTAT MATCHED offres DATA", res?.data);
        console.log("RESPONES GHERBI", res);
        setRows(res.data.payslips);
        setData(res.data);
        setLoading(false);
        console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        console.log(err);
        setRows([]);
      });
  };
  //custom footer
  const customFooter = () => {
    return <Box></Box>;
  };
  const modifyFiche = (values) => {
    Api.patch(
      Urls.MODIFY_FICHE_DE_PAIE + values.id,
      {
        status: values.status,
        amount: values.montant,
        number: values.number,
        date: values.date,
        userId: values.id_user,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setChange(!change);
        setOpenModify(false);
      })
      .catch((err) => {
        console.log(err);
        setOpenModify(false);
      });
  };
  //search entreprise
  const [users, setUsers] = useState([]);
  const searchUsers = (value) => {
    Api.get(Urls.GET_LISTE_RENARD + `?perPage=${25}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(value !== "" && { nom: value }),
      },
    })
      .then((res) => {
        console.log(res.data);
        setUsers(res?.data?.interims);
      })
      .catch((err) => {
        console.log(err);
        setUsers([]);
      });
  };
  return (
    <Box>
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography variant="h4" component="h1">
          Gestion des fiches de paie
        </Typography>
      </Box>

      {/* Form Section */}
      <Box className="filter" flexWrap="wrap" gap="1rem" display="flex" flexDirection="column">
        <Box>
          <Typography noWrap mr marginTop="0.45rem" fontWeight="600">
            Filtre :
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" sx={{ flexWrap: "wrap", flexDirection: { md: "row", xs: "column" } }}>
            {/*Search fields  */}
            <Box display="flex" alignItems="center" marginRight="1rem" mb>
              <Box display="flex">
                <label style={{ marginRight: "0.5rem" }} htmlFor="fiche">
                  N° de la fiche de paie
                </label>
              </Box>
              <Box display="flex">
                <TextField type="number" name="fiche" placeholder="Numéro de la fiche de paie" onChange={formik.handleChange} value={formik.values.fiche} />
              </Box>
            </Box>
            <Box display="flex" alignItems="center" marginRight="1rem" mb>
              <label style={{ marginRight: "0.5rem" }} htmlFor="interimaire">
                intérimaire
              </label>
              <TextField name="interimaire" placeholder="intérimaire" onChange={formik.handleChange} value={formik.values.interimaire} />
            </Box>
            <Box display="flex" alignItems="center" marginRight="1rem" mb>
              <Box display="flex">
                <label style={{ marginRight: "0.5rem" }} htmlFor="fiche">
                  Montant
                </label>
              </Box>
              <Box display="flex">
                <TextField type="number" name="montant" placeholder="Montant" onChange={formik.handleChange} value={formik.values.montant} />
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexWrap="wrap" alignItems="center" gap="0.3rem">
            <label sx={{ marginBottom: "0.3rem" }}>Selectionner une date</label>
            <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
              <DatePicker
                components={{ OpenPickerIcon: CalendarMonthIcon }}
                value={formik.values.datemin}
                views={["year", "month", "day"]}
                maxDate={new Date()}
                onChange={(value) => formik.setFieldValue("datemin", value)}
                InputProps={{ sx: { "& .MuiSvgIcon-root": { color: `${colors.main}` } } }}
                renderInput={(params) => {
                  return (
                    <TextField
                      type="date"
                      {...params}
                      error={formik.touched.datemin && Boolean(formik.errors.datemin)}
                      name="datemin"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "mois/année",
                        disabled: "true",
                      }}
                    />
                  );
                }}
              ></DatePicker>
            </LocalizationProvider>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={() => formik.setFieldValue("datemin", null)} />
          </Box>

          {/* Checkboxes */}
          <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column" }, gap: "0.5rem", marginBottom: "0.5rem" }}>
            <Box display="flex" alignItems="center">
              <label htmlFor="state">Etat :</label>
            </Box>

            <FormControlLabel
              label="Encaissée"
              control={
                <Checkbox
                  checked={formik.values.checkedPay}
                  onChange={(e) => {
                    formik.setFieldValue("checkedPay", e.target.checked);
                    e.target.checked === true ? setStatus((status) => status.concat(["PAYED"])) : setStatus(status.filter((status) => status !== "PAYED"));
                  }}
                />
              }
            />
            <FormControlLabel
              label="Non encaissée"
              control={
                <Checkbox
                  checked={formik.values.checkedImp}
                  onChange={(e) => {
                    formik.setFieldValue("checkedImp", e.target.checked);
                    e.target.checked === true ? setStatus((status) => status.concat(["NOT_PAYED"])) : setStatus(status.filter((status) => status !== "NOT_PAYED"));
                  }}
                />
              }
            />
          </Box>
          {/* End form section */}

          {/* Actions Section */}
          <Box className="actions">
            <Box display="flex" alignItems="left" gap="0.5rem" mb sx={{ flexDirection: { md: "row", xs: "column" } }}>
              <Typography noWrap mr marginTop="0.4rem" fontWeight="600">
                Actions :
              </Typography>
            </Box>
          </Box>
        </form>
      </Box>
      {/* Data grid section */}
      {/* Data grid container (styles imported from DataGridHeaderContainer) */}
      <Box sx={DataGridHeaderStyle}>
        <DataGrid
          // sx={{ maxWidth: "870px" }}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          onSelectionModelChange={(ids) => {
            const selection = new Set(ids);
            setSelectedIDs(ids);
            const selectedRows = rows.filter((row) => selection.has(row.id));
            setSelectedRows(selectedRows);
          }}
          rows={rows}
          columns={columns}
          pageSize={rowsPerPage}
          rowCount={total}
          page={page - 1}
          onPageChange={(page) => {
            setPage(page - 1);
          }}
          paginationMode="server"
          loading={loadin}
          checkboxSelection
          rowsPerPageOptions={[10, 30, 60]}
          onPageSizeChange={(size) => {
            setRowsPer(size);
          }}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
              labelRowsPerPage: `Nombre de lignes par page :`,
            },
            footerRowSelected: (count) => (count !== 1 ? `${count.toLocaleString()} lignes selectionnées` : `${count.toLocaleString()} ligne selectionnée`),
          }}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
            Footer: customFooter,
          }}
          getRowId={(row) => row.id}
        />
      </Box>
      {rows?.length > 0 && (
        <Box marginTop="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
          <Box flex={1}>
            {data?.links?.prev && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={leftArrowIcon} alt="arrow-left" />
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
            {data?.meta?.links?.map((link, index) => (
              <>
                {index !== 0 && index !== data?.meta?.last_page + 1 && (
                  <ButtonSquare
                    onClick={() => {
                      handlePagination(link.url);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action={link?.active ? "number-active" : "number"}
                    number={link.label}
                  />
                )}
              </>
            ))}
          </Box>
          <Box flex={1}>
            {data?.links?.next && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={arrowRightIcon} alt="arrow-right" />
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
      <Modal open={openModify} onClose={() => setOpenModify(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Modifier fiche de paie #{initialValuesModify.coffreo_oid}{" "}
              </Typography>
              <ButtonSquare onClick={() => setOpenModify(false)} action="close" />
            </Box>
            <Formik
              initialValues={initialValuesModify}
              enableReinitialize={true}
              onSubmit={(values) => {
                modifyFiche(values);
              }}
              validationSchema={validationSchema}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box gap="20px" display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Montant
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.montant} name="montant" placeholder="Montant..." />
                        {errors.montant && touched.montant ? (
                          <Typography color="red" fontSize="12px">
                            {errors.montant}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Etat
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <Box alignItems="center" gap="0.3rem" display="flex">
                          <FormControl placeholder="Etat" sx={{ maxWidth: "170px", minWidth: "170px", margin: "0.3rem 0rem 0rem 0rem" }}>
                            <Select placeholder="Etat" fullWidth value={values.status} onChange={(e) => setFieldValue("status", e.target.value)} input={values.status ? null : <OutlinedInput sx={{ color: "black" }} label="status ... " />}>
                              <MenuItem key="PAYED" value="PAYED">
                                Encaissée
                              </MenuItem>
                              <MenuItem key="NOT_PAYED" value="NOT_PAYED">
                                Non encaissée
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Intérimaire
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <Autocomplete
                          disablePortal
                          ListboxProps={{ style: { maxHeight: "180px" } }}
                          fullWidth
                          id="combo-box-demo"
                          value={values?.interimaire}
                          onChange={(e, value) => {
                            if (value) {
                              setFieldValue("id_user", value.id);
                              setFieldValue("interimaire", {
                                id_user: value.id,
                                first_name: value.first_name,
                                last_name: value.last_name,
                              });
                            }
                          }}
                          options={users}
                          getOptionLabel={(Option) => Option.first_name + " " + Option.last_name}
                          renderInput={(params) => (
                            <TextField
                              sx={{ maxWidth: "100%" }}
                              value={values.titre}
                              onChange={(e) => {
                                if (e.target.value) {
                                  if (e.target.value?.length > 0) {
                                    searchUsers(e?.target?.value);
                                  } else {
                                    setUsers([]);
                                  }
                                }
                              }}
                              {...params}
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={RechercheIcon} alt="end" />
                                  </InputAdornment>
                                ),
                                endAdornment: null,
                              }}
                              placeholder="Intérimaire..."
                            />
                          )}
                        />{" "}
                        {errors.id_user && touched.id_user ? (
                          <Typography color="red" fontSize="12px">
                            {errors.id_user}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Numéro de la fiche de paie
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.number} name="number" placeholder="Numéro de la fiche de paie..." />
                        {errors.number && touched.number ? (
                          <Typography color="red" fontSize="12px">
                            {errors.number}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Date
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <Field value={values.date} setFieldValue={setFieldValue} name="date" placeholder="Date..." maxDate={new Date()} as={MyDPFullDate}></Field>
                        {errors.date && touched.date ? (
                          <Typography color="red" fontSize="12px">
                            {errors.date}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" alignItems="center" gap="8px">
                      <Button onClick={() => setOpenModify(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button type="submit" variant="mj_primary_md">
                        Enregistrer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default Factures;
