import React, { useEffect, useLayoutEffect } from "react";
import { useState, useRef } from "react";
import { Box, TextField, Button, Typography, Avatar, InputAdornment, IconButton, Stack, CircularProgress } from "@mui/material";
import { colors } from "../../theme/Colors";
import ContentBox from "../../components/ui/ContentBox";
import LightOrangeBox from "../../components/ui/LightOrangeBox";
import userIcon from "../../imgs/user.png";
import { Formik, Form, Field } from "formik";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import Api from "../../api/Api";
import { ApiOutlined } from "@mui/icons-material";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import Pusher from "pusher-js";
import moment from "moment";
import ButtonSquare from "../../components/ui/ButtonSquare";

const notselectedStyle = {
  backgroundColor: `white`,
  border: `2px solid ${colors.main}`,
  color: `${colors.main}`,
  "&:hover": { backgroundColor: `${colors.main}`, color: "white", border: `2px solid ${colors.main}` },
};
const SelectedStyle = {
  backgroundColor: `${colors.main}`,
  border: `2px solid ${colors.main}`,
  color: "white",
  "&:hover": { backgroundColor: `${colors.main}`, border: `2px solid ${colors.main}` },
};

function Messagerie() {
  const [loading, setLoading] = useState(true);
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  window.scrollTo(0, 100);
  const [users, setUsers] = useState([]);
  const [userId, setUserID] = useState(null);
  const [loadinglist, setLoadingList] = useState(true);
  const [userM, setUser] = useState({});
  const [type, setType] = useState("interim");
  let length = 0;
  const buttonCandidat = useRef();
  const buttonEntreprise = useRef();
  const idC = user?.user?.id;
  const bottomRef = useRef();
  const [Change, setChange] = useState(0);

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: "eu",
    });
    const channel = pusher.subscribe(`messaging.user.${user?.user?.id}`);
    channel?.bind("NewMessageEvent", function (data) {
      setDiscussion((discussion) => [data?.message, ...discussion]);
    });

    return () => {
      pusher.unsubscribe(`messaging.user.${user?.user?.id}`);
    };
  }, []);

  // console.log("CHANNEL OBJECT", channel);

  useLayoutEffect(() => {
    setLoadingList(true);
    setLoading(true);

    Api.get(`${Urls.GET_LIST_USERS_MESSAGES}`, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: {
        type: `${type}`,
      },
    })
      .then((res) => {
        if (res.data.users.length > 0) {
          setUsers(res?.data?.users);
          setUserID(res?.data?.users[0].id);
          setLoadingList(false);
          setUser(res?.data?.users[0]);
          console.log("UTILISATEURS de chat ::: ", res?.data?.users);
        } else {
          console.log("UTILISATEURS de chat VIDE!! ");
          setLoadingList(false);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(access_token);
      });
  }, [type]);

  //scroll bottom when conversation updates
  const [discussion, setDiscussion] = useState([]);
  const [data, setData] = useState({});

  //get discussion
  useEffect(() => {
    setLoading(true);

    console.log("JSON TO SEND TO GET MESSAGES CONVERSATION : ", userId);

    console.log("USER ID :", userId + " MESSAGES");
    if (userId !== null) {
      Api.get(`${Urls.GET_MESSAGES}`, {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
        params: {
          user_id: userId,
        },
      })
        .then((res) => {
          length += res.data.messages.length;
          console.log("LES MESSAGES", res?.data);
          setDiscussion(res?.data.messages);
          setData(res?.data);
          setUser(users.find((user) => user.id === userId));
          if (res?.data?.messages.length > 0) {
            console.log(res.data);
            setTimeout(() => {
              if (bottomRef) {
                bottomRef.current.scrollTop = 492 * length;
              }
            }, 1000);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else setChange(Change + 1);
  }, [Change, userId]);

  const [messages, setMessages] = useState("");
  const field = useRef();

  const handleAddMessage = (e) => {
    if (userId === null) {
      return;
    }

    Api.post(
      `${Urls.SEND_MESSAGE}`,
      {
        body: messages,
        user_id: userId,
      },
      {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        let newListe = discussion;
        setDiscussion((discussion) => [res?.data?.data, ...discussion]);
      })
      .catch((err) => {
        console.log(err);
      });
    setMessages("");
  };

  const handlePagination = () => {
    Api.get(`${data.links.next}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setDiscussion((discussion) => discussion.concat(res.data.messages));
        setData(res?.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };
  function afficherDifferenceDate(date) {
    const now = moment();
    const diff = moment(date);
    const Auj = moment(new Date()).format("yyyy-MM-DD");
    const dateF = moment(date).format("yyyy-MM-DD");
    console.log(dateF, Auj);
    const minutes = now.diff(diff, "minutes");
    const hours = now.diff(diff, "hours");
    const jours = now.diff(diff, "days");
    const semaines = now.diff(diff, "weeks");
    const mois = now.diff(diff, "months");
    console.log("nombre de jours", jours);
    if (dateF === Auj) {
      return date?.split(" ")[1].split(":")[0] + "h" + date?.split(" ")[1].split(":")[1];
    } else {
      if (dateF !== Auj && hours < 24) {
        return "Hier à " + date?.split(" ")[1].split(":")[0] + "h" + date?.split(" ")[1].split(":")[1];
      }
      if (jours === 1) {
        return "Hier à " + date?.split(" ")[1].split(":")[0] + "h" + date?.split(" ")[1].split(":")[1];
      }
      if (jours === 2) {
        return "Avant hier à " + date?.split(" ")[1].split(":")[0] + "h" + date?.split(" ")[1].split(":")[1];
      }
      if (jours > 2) {
        return moment(date).format("dddd DD MMMM yyyy");
      }
    }
  }
  return (
    <Box>
      {/* Header Section */}
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography variant="h4" component="h1">
          Messagerie
        </Typography>
      </Box>

      {/* Message component Section */}
      <Box display="flex" gap="2rem" sx={{ flexDirection: { xs: "column-reverse", md: "row", sm: "column-reverse", lg: "row" } }}>
        <Box display="flex" flexDirection="column" sx={{ width: { xs: "400px", sm: "500px", md: "500px", lg: "600px" } }} height="700px" backgroundColor="white" zIndex="99999">
          <Box sx={{ width: "100%", border: "1px solid #DEDFE4", padding: "12px", borderTopLeftRadius: "6px", borderTopRightRadius: "6px" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <Typography fontSize="18px" fontWeight="600" lineHeight="160%">
                  👨‍💻 {userM?.first_name} {userM?.last_name} {userM?.name}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box flex={15} display="flex" flexDirection="column-reverse" ref={bottomRef} sx={{ overflowY: "scroll", width: "100%", borderBottom: "1px solid #DEDFE4", borderRight: "1px solid #DEDFE4", borderLeft: "1px solid #DEDFE4" }}>
            {discussion?.map((disc, index) => (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {discussion[index + 1] && disc?.created_at?.split(" ")[0] !== discussion[index + 1]?.created_at?.split(" ")[0] && (
                  <Box sx={{ marginBottom: "20px" }} display="flex" alignItems="center">
                    <Box style={{ flex: "3", margin: "0px 5px", padding: "5px", display: "flex", justifyContent: "center" }}>
                      <Typography variant="text_gray" sx={{ textAlign: "center" }}>
                        {index !== discussion?.length ? afficherDifferenceDate(discussion[index]?.created_at) : null}
                        {discussion?.length === 1 ? afficherDifferenceDate(discussion[index]?.created_at) + "a" : null}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {discussion?.length >= 1 && index === discussion?.length - 1 && (
                  <Box sx={{ marginBottom: "20px" }} display="flex" alignItems="center">
                    <Box style={{ flex: "3", margin: "0px 5px", padding: "5px", display: "flex", justifyContent: "center" }}>
                      <Typography variant="text_gray" sx={{ textAlign: "center" }}>
                        {afficherDifferenceDate(discussion[index]?.created_at)}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {disc.sent_by_user_id === user?.user?.id ? (
                  <Box key={disc.id} display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" gap="0.3rem" margin="2px 20px">
                    <Box padding="10px" backgroundColor={colors.main} sx={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px", borderBottomLeftRadius: "20px", borderBottomRightRadius: "6px", margin: "0", width: "fit-content" }}>
                      <Typography sx={{ color: "white", overflowWrap: "break-word", whiteSpace: "pre-line", maxWidth: "150px", display: "block" }}>{disc?.body}</Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box key={disc.id} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" gap="0.3rem" margin="2px 20px">
                    <Box backgroundColor="rgba(245, 245, 247, 1)" borderRadius="20px 20px 20px 4px" padding="10px" sx={{ magin: "0", width: "fit-content" }}>
                      {" "}
                      <Typography sx={{ overflowWrap: "break-word", whiteSpace: "pre-line", maxWidth: "150px", display: "block" }}>{disc?.body}</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            ))}
            {data?.links?.next ? (
              <Stack margin="1REM 0REM" direction="column" justifyContent="center" alignItems="center">
                <Button onClick={handlePagination} variant="mj_secondary_md">
                  Changer plus de messages
                </Button>
              </Stack>
            ) : null}
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ borderBottomLeftRadius: "6px", borderBottomRightRadius: "6px", width: "100%", borderBottom: "1px solid #DEDFE4", borderRight: "1px solid #DEDFE4", borderLeft: "1px solid #DEDFE4", padding: "12px" }}>
            <Box flex={4}>
              <input value={messages} onChange={(e) => setMessages(e.target.value)} type="text" placeholder="Votre message" style={{ fontSize: "14px", fontWeight: "400", width: "100%", height: "30px", outline: "none", border: "none" }} />
            </Box>
            <Box>
              <ButtonSquare
                onClick={() => {
                  if (messages) handleAddMessage();
                }}
                action="send-message"
              />
            </Box>
          </Box>
        </Box>
        <LightOrangeBox sx={{ margin: "0", maxWidth: "800px", overflowY: "scroll", overflowX: "scroll" }} marginLeft="1rem">
          <Box width="100%" display="flex" gap="1rem" sx={{ justifyContent: { xs: "center", sm: "center", md: "start", lg: "start" } }}>
            <Button
              onClick={() => {
                setType("interim");
              }}
              variant="mj_primary"
              ref={buttonCandidat}
              sx={type === "enterprise" ? notselectedStyle : SelectedStyle}
            >
              candidat
            </Button>
            <Button
              onClick={() => {
                setType("enterprise");
              }}
              variant="mj_primary"
              ref={buttonEntreprise}
              sx={type === "interim" ? notselectedStyle : SelectedStyle}
            >
              entreprise
            </Button>
          </Box>
          <Box marginTop="2rem" gap="0.5rem" display="flex" sx={{ flexDirection: { xs: "row", sm: "row", md: "column", lg: "column" } }}>
            {loadinglist === false ? (
              users.length > 0 ? (
                type === "interim" ? (
                  users
                    .filter((user) => user.account_type === "INTERIM")
                    .map((user) => (
                      <Box
                        onClick={() => setUserID(user.id)}
                        key={user.id}
                        backgroundColor={userId === user.id ? `${colors.main}` : "rgb(77, 86, 168, 0.28)"}
                        color={userId === user.id ? "white" : "black"}
                        padding="0.5rem 0.5rem"
                        borderRadius="15px"
                        sx={{ cursor: "pointer", display: "flex", gap: "0.3rem", justifyContent: { xs: "center", sm: "center", md: "start", lg: "start" }, flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, alignItems: "center" }}
                        className="discussion"
                      >
                        <img style={{ height: "40px", width: "40px", borderRadius: "50%" }} src={user.url_picture_profile ? user.url_picture_profile : userIcon} />
                        <Box>
                          <Typography sx={{ textAlign: "center", maxWidth: { xs: "90px", sm: "90px", md: "160px", lg: "160px" }, whiteSpace: "pre-line", wordBreak: "break-word" }}>
                            {user.first_name} {user.last_name}
                          </Typography>
                        </Box>
                      </Box>
                    ))
                ) : type === "enterprise" ? (
                  users
                    .filter((user) => user.account_type === "ENTERPRISE")
                    .map((user) => (
                      <Box
                        onClick={() => setUserID(user?.id)}
                        key={user.id}
                        backgroundColor={userId === user.id ? `${colors.main}` : "rgb(77, 86, 168, 0.28)"}
                        color={userId === user.id ? "white" : "black"}
                        padding="0.5rem 0.5rem"
                        borderRadius="15px"
                        sx={{ cursor: "pointer", display: "flex", gap: "0.3rem", justifyContent: { xs: "center", sm: "center", md: "start", lg: "start" }, flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, alignItems: "center" }}
                        className="discussion"
                      >
                        <img style={{ height: "40px", width: "40px", borderRadius: "50%" }} src={user.url_picture_profile ? user.url_picture_profile : userIcon} />
                        <Box>
                          <Typography sx={{ textAlign: "center", maxWidth: { xs: "90px", sm: "90px", md: "160px", lg: "160px" }, whiteSpace: "pre-line", wordBreak: "break-word" }}>{user.name}</Typography>
                        </Box>
                      </Box>
                    ))
                ) : null
              ) : (
                <Typography>Aucun message</Typography>
              )
            ) : (
              <Stack direction="row" justifyContent="center" alignItems="center">
                {" "}
                <CircularProgress sx={{ color: `${colors.main_mj}` }} />
              </Stack>
            )}
          </Box>
        </LightOrangeBox>
      </Box>
    </Box>
  );
}

export default Messagerie;
