import React, { useState, useEffect } from "react";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { Box, TextField, Button, Typography, Checkbox, IconButton, FormLabel, Radio, FormControlLabel, RadioGroup, MenuItem, OutlinedInput, Select, InputLabel, FormControl, Alert, Modal } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ButtonDataGrid from "../../components/ui/ButtonDataGrid";
import { chipSelector } from "../../utils/chipSelector";
import leftArrowIcon from "../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../imgs/imgV2/arrowRightBlavk.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LinearProgress from "@mui/material/LinearProgress";
import { rows, columns } from "../../data/OffresData";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import { Formik, Form, Field, useFormik } from "formik";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { useAuthContext } from "../../hooks/useAuthContext";
import axios from "axios";
import * as yup from "yup";
import { colors } from "../../theme/Colors";
import { ArrowBackIosOutlined } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useLocation, useNavigate } from "react-router";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ButtonSquare from "../../components/ui/ButtonSquare";
import MyChip from "../../components/ui/MyChip";
import { modalStyle } from "../../theme/ModalStyle";
import BoxContainer from "../../components/ui/BoxContainer";
import { ToastContainer, toast } from "react-toastify";

function Offres() {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const [action, setAction] = useState("");
  const [openParams, setOpenParams] = React.useState(false);
  const { user } = useAuthContext();
  const location = useLocation();
  const idEnt = location?.state?.id ? location?.state?.id : "";

  const [rows, setRows] = useState([]);
  const [id, setId] = useState(null);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(false);

  const [rowsPerPage, setRowsPer] = useState(10);
  const [finalValues, setFieldValue] = useState(0);

  const validationSchema = yup.object().shape({
    tauxHoraireMax: yup.number().typeError("entrer un nombre").min(0, "entrer un nombre valide"),
    tauxHoraireMin: yup.number().typeError("entrer un nombre").min(0, "entrer un nombre valide"),
  });
  const [initialValues, setInitialValues] = useState({
    id: "",
    entrepriseName: "",
    enterpriseId: idEnt,
    intitulePoste: "",
    isVisible: "",
    status: "",
    isVerified: "",
  });
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {
      setFieldValue(finalValues + 1);
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });
  const access_token = user?.access_token;

  //GET LISTE DES OFFRES + FILTRE
  useEffect(() => {
    setLoading(true);
    Api.get(Urls.GET_ADMIN_OFFERS + `?perPage=${25}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(formik.values.id !== "" && { id: formik.values.id }),
        ...(formik.values.entrepriseName !== "" && { enterpriseName: formik.values.entrepriseName }),
        ...(formik.values.enterpriseId !== "" && { enterpriseId: formik.values.enterpriseId }),
        ...(formik.values.intitulePoste !== "" && { jobTitle: formik.values.intitulePoste }),
        ...(formik.values.status !== "" && { isActive: formik.values.status }),
        ...(formik.values.isVisible !== "" && { isApplicationOpen: formik.values.isVisible }),
        ...(formik.values.isVerified !== "" && { isVerified: formik.values.isVerified }),
      },
    })
      .then((res) => {
        console.log(res);
        setRows(res.data.offers);
        setData(res.data);
        setLoading(false);
        setTotal(res?.data?.meta?.total);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [formik.values, change]);
  //handle Pagination
  const handlePagination = (link) => {
    setLoading(true);
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("RESULTAT MATCHED offres DATA", res?.data);
        console.log("RESPONES GHERBI", res);
        setRows(res.data.offers);
        setData(res.data);
        setLoading(false);
        setTotal(res?.data?.meta?.total);
        console.log(res?.data?.meta?.links, "link pag");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setRows([]);
      });
  };

  const columns = [
    { field: "id", headerName: "ID demande", width: 90, headerClassName: "grid-header" },
    {
      field: "enterprise_id",
      headerName: "ID entreprise",
      width: 100,
      renderCell: (params) => {
        if (params.row.enterprise_id === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.enterprise_id;
        }
      },
    },
    {
      field: "enterprise_name",
      headerName: "Entreprise",
      width: 150,
      renderCell: (params) => {
        if (params.row.enterprise_name === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.enterprise_name;
        }
      },
    },

    {
      field: "job_title",
      headerName: "Intitulé de poste",
      width: 200,
      renderCell: (params) => {
        if (params.row.job_title === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.job_title;
        }
      },
    },
    {
      field: "nbr_applications",
      headerName: "NB candidature",
      width: 100,
      align: "center",
      renderCell: (params) => {
        if (params.row.nbr_applications === null) {
          return <Typography>-</Typography>;
        } else {
          return params.row.nbr_applications;
        }
      },
    },
    /* {
      field: "is_visible",
      headerName: "Visibilité",
      width: 150,
      align: "center",
      renderCell: (params) => {
        if (params.row.is_visible === null) {
          return <Typography>Non defini</Typography>;
        } else {
          return chipSelector(params.row.is_visible);
        }
      },
    }, */
    {
      field: "is_active",
      headerName: "Etat",
      description: "This column has a value getter and is not sortable.",
      width: 110,
      align: "center",
      renderCell: (params) => {
        return params.row.is_active === 1 ? <MyChip variant="active_offer" label="Active" /> : <MyChip variant="ended_offer" label="Terminée" />;
      },
    },
    {
      field: "is_application_open",
      headerName: "Visibilité",
      description: "This column has a value getter and is not sortable.",
      width: 160,
      align: "center",
      renderCell: (params) => {
        return params.row.is_application_open === 1 ? <MyChip variant="ended_offer" label="Publiée" /> : <MyChip variant="rejected_offer" label="Non publié" />;
      },
    },
    {
      field: "is_verified",
      headerName: "Verification",
      description: "This column has a value getter and is not sortable.",
      width: 160,
      align: "center",
      renderCell: (params) => {
        return params.row.is_verified === 1 ? <MyChip variant="ended_offer" label="Vérifié" /> : <MyChip variant="rejected_offer" label="Non vérifié" />;
      },
    },
    {
      field: "action",
      headerName: "Action",
      description: "This column has a value getter and is not sortable.",
      align: "center",

      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="row" gap="0.2rem" alignItems="center">
            <ArrowCircleRightIcon
              action={"details"}
              sx={{ cursor: "pointer", color: `${colors.mjGreenOutline}`, fontSize: 40 }}
              onClick={() =>
                navigate("/admin/gestion-des-offres/details/" + params.row.id, {
                  state: {
                    title: params.row.job_title,
                    postedSince: params.row.created_at,
                    status: params.row.is_active,
                    is_active: params.row.is_active,
                    is_application_open: params.row.is_application_open,
                    is_verified: params.row.is_verified,
                  },
                })
              }
            />{" "}
          </Box>
        );
      },
      width: 160,
      valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
  ];
  //animate open params
  const animateOpen = {
    transform: "rotate(90deg)",
    transition: "0.25s",
  };
  const animateClose = {
    transform: "rotate(0deg)",
    transition: "0.25s",
  };

  const customFooter = () => {
    return <Box></Box>;
  };
  //publier offre
  const publishOffers = (status) => {
    Api.post(
      Urls.OPEN_CLOSE_OFFER,
      {
        offer_ids: selectedIDs,
        is_application_open: status,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res.data);
        setOpen(false);
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err.messages);
        toast.error(err?.response?.data.message);
      });
  };

  //delete offer
  const VerifyOffer = (status) => {
    Api.post(
      Urls.VERIFY_OFFER,
      {
        offer_ids: selectedIDs,
        is_verified: status,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res.data);
        setOpen(false);
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err.messages);
        toast.error(err?.response?.data.message);
      });
  };
  //accept offer
  const activateOffer = (status) => {
    Api.post(
      Urls.ACTIATE_DEACTIVATE_OFFER,
      {
        offer_ids: selectedIDs,
        is_active: status,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res.data);
        setOpen(false);
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        console.log(err.messages);
        toast.error(err?.response?.data.message);
      });
  };
  const fuctionSelector = (selection) => {
    console.log(selection, "selection");
    switch (selection) {
      case "ne pas vérifier":
        return VerifyOffer(0);
        break;
      case "vérifier":
        return VerifyOffer(1);
        break;
      case "activer":
        return activateOffer(1);
        break;
      case "terminer":
        return activateOffer(0);
        break;
      case "publier":
        return publishOffers(1);
        break;
      case "ne pas publier":
        return publishOffers(0);
        break;
    }
  };
  return (
    <Box>
      {/* Header Section */}
      <ToastContainer />
      <Box className="header" sx={{ marginBottom: "1rem" }}>
        <Typography noWrap variant="h4" component="h1">
          Gestion des demandes
        </Typography>
      </Box>

      {/* Form Section */}
      <form onSubmit={formik.handleSubmit}>
        <Box className="filter" display="flex" flexDirection="column">
          <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column", lg: "column" } }}>
            <Box>
              <Typography noWrap mr marginTop="0.45rem" fontWeight="600">
                Filtre :
              </Typography>
            </Box>

            {/*Search fields  */}

            <Box display="flex" flex={1} alignItems="center" marginRight="1rem" gap="1rem" flexWrap="wrap" mb>
              <Box gap="0.3rem" display="flex" alignItems="center">
                <label style={{ marginRight: "0.5rem" }} htmlFor="interimaire">
                  ID entreprise
                </label>
                <TextField value={formik.values.enterpriseId} type="number" name="enterpriseId" onChange={formik.handleChange} placeholder="ID entreprise" />
              </Box>
              <Box gap="0.3rem" display="flex" alignItems="center">
                <label style={{ marginRight: "0.5rem" }} htmlFor="interimaire">
                  Entreprise
                </label>
                <TextField name="entrepriseName" onChange={formik.handleChange} placeholder="entreprise" />
              </Box>
              <Box gap="0.3rem" display="flex" alignItems="center">
                <label style={{ marginRight: "0.5rem" }} htmlFor="interimaire">
                  ID demande
                </label>
                <TextField type="number" name="id" onChange={formik.handleChange} placeholder="ID demande" />
              </Box>
              <Box gap="0.3rem" display="flex" alignItems="center">
                <label htmlFor="intitulePoste">Intitulé du poste : </label>
                <TextField name="intitulePoste" onChange={formik.handleChange} placeholder="Intitulé du poste" />
              </Box>
            </Box>
          </Box>
          {/* Checkboxes */}
          <Box display="flex" sx={{ flexDirection: "column", gap: "0.5rem", marginBottom: "0.5rem" }}>
            {/* etat */}
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">Etat : </FormLabel>
              <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="status" value={formik.values.status} onChange={(e) => formik.setFieldValue("status", e.target.value)}>
                <FormControlLabel value={0} control={<Radio />} label="Terminée" />
                <FormControlLabel value={1} control={<Radio />} label="Active" />
                <FormControlLabel
                  value=""
                  control={<Radio />}
                  label="Par Défaut
"
                />
              </RadioGroup>
            </FormControl>

            {/* visibilité */}
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">Visibilité : </FormLabel>
              <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="isVisible" value={formik.values.isVisible} onChange={(e) => formik.setFieldValue("isVisible", e.target.value)}>
                <FormControlLabel value={1} control={<Radio />} label="Publiée" />
                <FormControlLabel value={0} control={<Radio />} label="Non publiée" />
                <FormControlLabel
                  value=""
                  control={<Radio />}
                  label="Par Défaut
"
                />
              </RadioGroup>
            </FormControl>
            {/* Vérification */}
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">Vérification : </FormLabel>
              <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="isVerified" value={formik.values.isVerified} onChange={(e) => formik.setFieldValue("isVerified", e.target.value)}>
                <FormControlLabel value={1} control={<Radio />} label="Vérifié" />
                <FormControlLabel value={0} control={<Radio />} label="Non vérifié" />
                <FormControlLabel
                  value=""
                  control={<Radio />}
                  label="Par Défaut
"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        {/* End form section */}

        {/* Actions Section */}
        <Box className="actions">
          <Box display="flex" alignItems="left" gap="10px" mb flexDirection="column">
            <Typography noWrap mr marginTop="0.4rem" fontWeight="600">
              Actions :
            </Typography>
            {/* actions etat */}
            <Box display="flex" flexDirection="column" gap="0.5rem">
              <Box display="flex" flexDirection="row" gap="0.5rem" alignItems="center">
                <Box display="flex" gap="1rem">
                  <Button onClick={() => navigate("/admin/gestion-des-offres/nouvelle-offre", { state: { new: true } })} variant="mj_green_md">
                    Ajouter une demande
                  </Button>
                </Box>
              </Box>

              {/* actions visibilité */}
            </Box>
            <Box display="flex" flexDirection="column" gap="10px">
              <Box display="flex" alignItems="center" gap="10px">
                <Typography>Etat</Typography>
                <Button
                  disabled={selectedIDs?.length === 0 ? true : false}
                  onClick={() => {
                    setOpen(true);
                    setAction("terminer");
                  }}
                  variant="mj_green_md"
                >
                  Terminer
                </Button>
                <Button
                  disabled={selectedIDs?.length === 0 ? true : false}
                  onClick={() => {
                    setOpen(true);
                    setAction("activer");
                  }}
                  variant="mj_blue_md"
                >
                  Activer
                </Button>
              </Box>
              <Box display="flex" alignItems="center" gap="10px">
                <Typography>Visibilité</Typography>
                <Button
                  disabled={selectedIDs?.length === 0 ? true : false}
                  onClick={() => {
                    setOpen(true);
                    setAction("publier");
                  }}
                  variant="mj_green_md"
                >
                  Publier
                </Button>
                <Button
                  disabled={selectedIDs?.length === 0 ? true : false}
                  onClick={() => {
                    setOpen(true);
                    setAction("ne pas publier");
                  }}
                  variant="mj_red_md"
                >
                  Non publier
                </Button>
              </Box>
              <Box display="flex" alignItems="center" gap="10px">
                <Typography>Vérification</Typography>
                <Button
                  disabled={selectedIDs?.length === 0 ? true : false}
                  onClick={() => {
                    setOpen(true);
                    setAction("vérifier");
                  }}
                  variant="mj_green_md"
                >
                  Vérifier
                </Button>
                <Button
                  disabled={selectedIDs?.length === 0 ? true : false}
                  onClick={() => {
                    setOpen(true);
                    setAction("ne pas vérifier");
                  }}
                  variant="mj_red_md"
                >
                  Non vérifier{" "}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
      {/* Data grid section */}
      {/* Data grid container (styles imported from DataGridHeaderContainer) */}
      <Box sx={DataGridHeaderStyle}>
        <DataGrid
          // sx={{ maxWidth: "1550px" }}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          onSelectionModelChange={(ids) => {
            const selection = new Set(ids);
            setSelectedIDs(ids);
            const selectedRows = rows.filter((row) => selection.has(row.id));
            setSelectedRows(selectedRows);
          }}
          rows={rows}
          columns={columns}
          pageSize={rowsPerPage}
          rowCount={total}
          rowsPerPageOptions={[10, 30, 60]}
          onPageSizeChange={(size) => {
            setRowsPer(size);
          }}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} de ${count}`,
              labelRowsPerPage: `Nombre de lignes par page :`,
            },
            footerRowSelected: (count) => (count !== 1 ? `${count.toLocaleString()} lignes selectionnées` : `${count.toLocaleString()} ligne selectionnée`),
          }}
          checkboxSelection
          disableSelectionOnClick
          page={page - 1}
          paginationMode="server"
          onPageChange={(page) => {
            setPage(page + 1);
          }}
          loading={loading}
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
            Footer: customFooter,
          }}
        />
      </Box>
      {rows?.length > 0 && (
        <Box marginBottom="24px" marginTop="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
          <Box flex={1}>
            {data?.links?.prev && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={leftArrowIcon} alt="arrow-left" />
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
            {data?.meta?.links?.map((link, index) => (
              <>
                {index !== 0 && index !== data?.meta?.last_page + 1 && (
                  <ButtonSquare
                    onClick={() => {
                      handlePagination(link.url);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action={link?.active ? "number-active" : "number"}
                    number={link.label}
                  />
                )}
              </>
            ))}
          </Box>
          <Box flex={1}>
            {data?.links?.next && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={arrowRightIcon} alt="arrow-right" />
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Modification de la demande
              </Typography>
              <ButtonSquare onClick={() => setOpen(false)} action="close" />
            </Box>
            <Typography sx={{ fontSize: "15px", marginBottom: "24px", fontWeight: "600", textAlign: "center" }}>Êtes-vous sûr(e) de vouloir {action} cette offre ?</Typography>
            <Box marginTop="24px" display="flex" gap="8px" justifyContent="flex-end" alignItems="center">
              <Button variant="mj_secondary_md" onClick={() => setOpen(false)}>
                Annuler
              </Button>
              <Button onClick={() => fuctionSelector(action)} variant="mj_primary_md">
                Enregistrer
              </Button>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default Offres;
